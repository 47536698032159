<template>
    <basic-dialog v-if="doShowStoryDialog" v-bind:option-a-label="'K...'" v-on:option-a-clicked="hideStoryDialog">
        <template v-slot:content>
            <div class="__dialog-content">
                <img src="./assets/images/space-command-flag.svg" class="__flag" />
                <h2>Space Briefing</h2>
                <div class="__divider" />
                <h3 class="--primary">
                    It is the year <span class="--highlight">2 million</span>. <span class="--highlight">1-4</span> of earth’s best
                    astronauts, the <span class="--highlight">space rangers</span>, are stuck in an abandoned
                    <span class="--highlight">space cave</span>. Race to the exit and make it out alive. Or don’t, it doesn’t really matter
                    do whatever.
                </h3>
            </div>
        </template>
    </basic-dialog>
    <div class="__debug-panel" v-if="debugModeActive">
        <h3>Profiler (shift + p)</h3>
        <p class="--small">
            Ping Speed:
            <span class="--fast" v-bind:class="{ '--medium': currPingTime > 20, '--bad': currPingTime > 80 }">{{ currPingTime }}</span>
        </p>
        <p class="--small">
            Game State update delta:
            <span class="--fast" v-bind:class="{ '--medium': gameStateUpdateDelta > 230, '--bad': gameStateUpdateDelta > 280 }">{{
                gameStateUpdateDelta
            }}</span>
        </p>
        <p class="--small">
            Frame render time:
            <span class="--fast" v-bind:class="{ '--medium': frameRenderTime > 10, '--bad': frameRenderTime > 30 }">{{
                frameRenderTime
            }}</span>
        </p>
        <p class="--small">
            Most expensive frame:
            <span class="--fast" v-bind:class="{ '--medium': mostExpensiveFrame > 10, '--bad': mostExpensiveFrame > 30 }">{{
                mostExpensiveFrame
            }}</span>
        </p>
    </div>
    <router-view
        v-bind:session-id="sessionId"
        v-bind:user-name="userName"
        v-bind:active-sessions="activeSessions"
        v-bind:users="users"
        v-bind:board-names="boardNames"
        v-bind:user-id="userId"
        v-bind:messages="messages"
        v-bind:selected-board-index="selectedBoardIndex"
        v-bind:game-data="gameData"
        v-bind:session-error="sessionError"
        v-bind:volume="volume"
        v-bind:ping="currPingTime"
        v-on:create-session="createSession"
        v-on:try-join-session="tryJoinSession"
        v-on:leave-session="leaveSession"
        v-on:assign-user-name="assignUserName"
        v-on:start-game="startGame"
        v-on:end-game="endGame"
        v-on:next-level="nextLevel"
        v-on:select-board="selectBoard"
        v-on:send-message="sendMessage"
        v-on:send-commands="sendCommands"
        v-on:play-audio="playAudio"
        v-on:start-audio-loop="startAudioLoop"
        v-on:stop-audio-loop="stopAudioLoop"
        v-on:show-story="showStoryDialog"
        v-on:frame-render-time="onFrameRendered"
        v-on:volume-changed="volumeChanged"
    />
</template>

<script>
import AudioManager from './classes/audioManager.js';
import { AudioEffects } from './classes/audioManager.js';
import { GameModel, StateUpdate, Item, Pawn, Vector2 } from '../minos-shared/index.js';

import BasicDialog from './components/BasicDialog.vue';

export default {
    name: 'App',
    components: {
        basicDialog: BasicDialog,
    },
    data() {
        return {
            userId: '',
            userName: '',
            sessionId: '',
            sessionStatus: '',
            messages: [],
            lastAuthoredMessageIndex: '',
            users: [],
            boardNames: [],
            connected: false,
            waitingForStatus: false,
            gameData: null,
            audioManager: null,
            lastUpdateTime: 0,
            selectedBoardIndex: 0,
            doShowStoryDialog: false,
            doShowActiveSessionsDialog: false,
            sessionError: '',
            volume: 0.5,
            // Debugging values...
            pingTimer: null,
            currPingTime: 0,
            lastPingTime: 0,
            gameStateUpdateDelta: 0,
            frameRenderTime: 0,
            mostExpensiveFrame: 0,
            last100Frames: [],
            debugModeActive: false,
            activeSessions: [],
        };
    },
    sockets: {
        disconnect: function () {
            this.connected = false;
            this.sessionId = '';
            this.sessionStatus = '';
            this.users = [];
            this.messages = [];
        },
        s2c_on_connection: function (userId) {
            this.connected = true;
            this.userId = userId;
            this.$socket.emit('c2s_on_connection_callback');
            this.getActiveSessions();
            this.pingTimer = setInterval(() => {
                this.lastPingTime = Date.now();
                this.$socket.emit('c2s_ping');
                if (this.route === 'login') {
                    this.getActiveSessions();
                }
            }, 2000);
        },
        s2c_ping_response: function () {
            this.currPingTime = Date.now() - this.lastPingTime;
        },
        s2c_session_error: function (errorCode) {
            this.sessionError = errorCode;
            // Clear out the error code because we watch and react to this error, if we get two errors that are the same, the watchers aren't triggered...
            setTimeout(() => {
                this.sessionError = '';
            }, 1000);
        },
        s2c_left_session: function () {
            this.sessionId = '';
            this.waitingForStatus = false;
        },
        s2c_board_selected: function (boardIndex) {
            this.selectedBoardIndex = boardIndex;
        },
        s2c_joined_session: function (args) {
            this.sessionId = args[0];
            this.sessionStatus = args[1];
            this.users = args[2];
            this.boardNames = args[3];
            this.selectedBoardIndex = args[4];
            if (this.sessionStatus === 'gameplay' && args.length > 5) {
                this.onGameStateChanged(args[5]);
            }
            this.waitingForStatus = false;
        },
        s2c_game_started: function (sessionStatus) {
            this.sessionStatus = sessionStatus;
            this.waitingForStatus = false;
        },
        s2c_game_ended: function (args) {
            this.sessionStatus = args[0];
            this.selectedBoardIndex = args[1];
            this.waitingForStatus = false;
        },
        s2c_messages_updated: function (messages) {
            this.messages = messages;
        },
        s2c_users_updated: function (users) {
            this.users = users;
        },
        s2c_on_gamestate_changed: function (obj) {
            this.onGameStateChanged(obj);
        },
        s2c_get_active_sessions: function (sessions) {
            this.activeSessions = sessions;
        },
        s2c_clear_game: function () {
            this.waitingForStatus = true;
            this.gameData = null;
        },
    },
    methods: {
        assignUserName(userName) {
            this.userName = userName;
        },
        createSession() {
            this.$socket.emit('c2s_create_session', this.userId, this.userName);
        },
        tryJoinSession(sessionId) {
            this.$socket.emit('c2s_try_join_session', this.userId, this.userName, sessionId);
        },
        leaveSession() {
            this.waitingForStatus = true;
            this.$socket.emit('c2s_leave_session', this.userId, this.sessionId);
            this.sessionId = '';
            this.sessionStatus = '';
            this.users = [];
            this.messages = [];
        },
        selectBoard(boardIndex) {
            this.$socket.emit('c2s_select_board', this.sessionId, boardIndex);
        },
        startGame() {
            this.waitingForStatus = true;
            this.gameData = null;
            this.$socket.emit('c2s_start_game', this.sessionId);
        },
        endGame() {
            this.waitingForStatus = true;
            this.gameData = null;
            this.$socket.emit('c2s_end_game', this.sessionId);
        },
        nextLevel() {
            this.waitingForStatus = true;
            this.gameData = null;
            this.$socket.emit('c2s_next_level', this.sessionId);
        },
        getActiveSessions() {
            if (this.connected) {
                this.$socket.emit('c2s_get_active_sessions');
            }
        },
        onGameStateChanged(obj) {
            // Debugging only...
            this.gameStateUpdateDelta = obj._timestamp - this.lastUpdateTime;
            this.lastUpdateTime = obj._timestamp;
            // End debug...
            if (!this.gameData) {
                this.gameData = {
                    lastProcessedCommand: -1,
                    model: new GameModel(),
                };
            }
            const stateUpdate = StateUpdate.cast(obj);
            const keys = Object.keys(stateUpdate.values);
            const phaseIndex = keys.indexOf('phase');
            const winnerIndex = keys.indexOf('winner');
            const timeIndex = keys.indexOf('time');
            const gameObjectsIndex = keys.indexOf('gameObjects');
            const gameObjectsMinifiedIndex = keys.indexOf('gameObjectsMinified');
            const processedCommandsIndex = keys.indexOf('processedCommands');
            const tilesIndex = keys.indexOf('tiles');
            const islandsIndex = keys.indexOf('islands');
            const islandsDictIndex = keys.indexOf('islandsDict');
            const exitPositionIndex = keys.indexOf('exitPosition');
            if (phaseIndex > -1) {
                this.gameData.model.phase = stateUpdate.values[keys[phaseIndex]];
            }
            if (winnerIndex > -1) {
                this.gameData.model.winner = stateUpdate.values[keys[winnerIndex]];
            }
            if (timeIndex > -1) {
                this.gameData.model.time = stateUpdate.values[keys[timeIndex]];
            }
            if (gameObjectsIndex > -1) {
                let gameObjects = [];
                for (let i = 0; i < stateUpdate.values[keys[gameObjectsIndex]].length; i++) {
                    if (stateUpdate.values[keys[gameObjectsIndex]][i]._type === 'pawn') {
                        gameObjects.push(Pawn.cast(stateUpdate.values[keys[gameObjectsIndex]][i]));
                    }
                    if (stateUpdate.values[keys[gameObjectsIndex]][i]._type === 'item') {
                        gameObjects.push(Item.cast(stateUpdate.values[keys[gameObjectsIndex]][i]));
                    }
                }
                this.gameData.model.gameObjects = gameObjects;
            }
            if (gameObjectsMinifiedIndex > -1) {
                const minifiedGameObjects = stateUpdate.values[keys[gameObjectsMinifiedIndex]];
                for (let i = 0; i < minifiedGameObjects.length; i++) {
                    const minifiedGameObject = minifiedGameObjects[i];
                    let fullGameObject = null;
                    for (let j = 0; j < this.gameData.model.gameObjects.length; j++) {
                        if (minifiedGameObject.id === this.gameData.model.gameObjects[j].id) {
                            fullGameObject = this.gameData.model.gameObjects[j];
                            break;
                        }
                    }
                    if (fullGameObject) {
                        // GameObject exists in the model...
                        if (minifiedGameObject.type === 'item') {
                            fullGameObject = Item.applyMinifiedValues(fullGameObject, minifiedGameObject);
                        }
                        if (minifiedGameObject.type === 'pawn') {
                            fullGameObject = Pawn.applyMinifiedValues(fullGameObject, minifiedGameObject);
                        }
                    } else {
                        // GameObject doesn't yet exist in the model...
                        if (minifiedGameObject.type === 'item') {
                            this.gameData.model.gameObjects.push(Item.castFromMinified(minifiedGameObject));
                        }
                        if (minifiedGameObject.type === 'pawn') {
                            this.gameData.model.gameObjects.push(Pawn.castFromMinified(minifiedGameObject));
                        }
                    }
                }
            }
            if (processedCommandsIndex > -1) {
                if (stateUpdate.values[keys[processedCommandsIndex]][this.userId]) {
                    this.gameData.lastProcessedCommand = stateUpdate.values[keys[processedCommandsIndex]][this.userId];
                }
            }
            if (tilesIndex > -1) {
                this.gameData.model.tiles = stateUpdate.values[keys[tilesIndex]];
            }
            if (islandsIndex > -1) {
                this.gameData.model.islands = stateUpdate.values[keys[islandsIndex]].map((island) => {
                    return {
                        id: island.id,
                        type: island.type,
                        tiles: island.tiles.map((tileV2) => {
                            return Vector2.cast(tileV2);
                        }),
                        doors: island.doors.map((doorV2) => {
                            return Vector2.cast(doorV2);
                        }),
                    };
                });
            }
            if (islandsDictIndex > -1) {
                this.gameData.model.islandsDict = stateUpdate.values[keys[islandsDictIndex]];
            }
            if (exitPositionIndex > -1) {
                this.gameData.model.exitPosition = stateUpdate.values[keys[exitPositionIndex]];
            }
        },
        sendMessage(msg) {
            this.$socket.emit('c2s_send_message', this.userId, this.sessionId, msg);
        },
        sendCommands(commands) {
            this.$socket.emit('c2s_send_commands', this.userId, this.sessionId, commands);
        },
        playAudio(clip, channel, volume) {
            this.audioManager.play(clip, channel, volume);
        },
        startAudioLoop(clip, channel, volume) {
            this.audioManager.startLoop(clip, channel, volume);
        },
        stopAudioLoop(clip, channel, volume) {
            this.audioManager.stopLoop(clip, channel, volume);
        },
        showStoryDialog() {
            this.doShowStoryDialog = true;
            this.playAudio(AudioEffects.CLICK, -1, 1);
        },
        hideStoryDialog() {
            this.doShowStoryDialog = false;
            this.playAudio(AudioEffects.CLICK, -1, 1);
        },
        onFrameRendered(renderTime) {
            if (this.debugModeActive) {
                this.frameRenderTime = this.fmtNumAsString(renderTime, 3);
                this.last100Frames.unshift(renderTime);
                if (this.last100Frames.length >= 100) {
                    if (this.debugModeActive) {
                        let mostExpensiveFrame = this.last100Frames[0];
                        for (let i = 0; i < this.last100Frames.length; i++) {
                            if (this.last100Frames[i] > mostExpensiveFrame) {
                                mostExpensiveFrame = this.last100Frames[i];
                            }
                        }
                        this.mostExpensiveFrame = this.fmtNumAsString(mostExpensiveFrame, 3);
                    }
                    this.last100Frames = [];
                }
            }
        },
        fmtNumAsString(num, numPlaces) {
            let numString = num.toString();
            while (numString.length < numPlaces) {
                numString = '0' + numString;
            }
            return numString;
        },
        volumeChanged(val) {
            this.volume = val;
        },
    },
    computed: {
        route() {
            if (!this.connected || this.waitingForStatus) {
                return '/';
            }
            if (this.sessionId === '') {
                return 'login';
            }
            return this.sessionStatus;
        },
    },
    watch: {
        route: {
            immediate: true,
            handler: function () {
                if (this.route === 'login') {
                    this.getActiveSessions();
                }
                this.$router.push(this.route);
            },
        },
        messages: {
            immediate: false,
            handler: function () {
                if (this.messages.length > 0) {
                    const lastMessage = this.messages[this.messages.length - 1];
                    if (
                        lastMessage.authorId !== this.userId &&
                        lastMessage.authorId !== -1 &&
                        lastMessage.messageIndex !== this.lastAuthoredMessageIndex
                    ) {
                        this.playAudio(AudioEffects.DING, -1, 1);
                        this.lastAuthoredMessageIndex = lastMessage.messageIndex;
                    }
                }
            },
        },
        volume: {
            immediate: false,
            handler: function () {
                if (this.audioManager) {
                    this.audioManager.volume = this.volume;
                }
            },
        },
    },
    created() {
        console.log('app created');
        this.audioManager = new AudioManager();
        this.audioManager.volume = this.volume;
        window.addEventListener('keydown', (event) => {
            if (event.code === 'KeyP' && event.shiftKey) {
                this.debugModeActive = !this.debugModeActive;
            }
        });
    },
};
</script>

<style lang="scss">
html,
body {
    margin: 0;
    padding: 0;
}

#app {
    margin: 0;
    padding: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: $color-background;
    overflow: hidden;
}

.__dialog-content {
    .__flag {
        width: 40px;
        margin-bottom: 5px;
    }
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.__debug-panel {
    position: absolute;
    z-index: 1100;
    width: 300px;
    padding: 10px;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
}
</style>