import SpriteView from './spriteView.js';
import { SpriteTypes } from './spriteView.js';
import { ITEM_SIZE, ItemStates } from './item.js';

const ItemAnimations = {
    IDLE: {
        loop: false,
        interval: 3,
        frames: [
            {
                x: 320,
                y: 288,
                w: ITEM_SIZE,
                h: ITEM_SIZE,
            },
        ]
    },
    DROPPED: {
        loop: true,
        interval: 15,
        frames: [
            {
                x: 320,
                y: 288,
                w: ITEM_SIZE,
                h: ITEM_SIZE,
            },
            {
                x: 320 + ITEM_SIZE,
                y: 288,
                w: ITEM_SIZE,
                h: ITEM_SIZE,
            },
        ]
    },
}

export default class ItemView extends SpriteView {

    constructor(id, itemType, scale) {

        super(id, 0, 0, ITEM_SIZE * scale, ITEM_SIZE * scale, SpriteTypes.ITEM, scale);

        this._itemType = itemType;
        this._intervalCtr = 0;
        this._currentFrame = 0;
        this._currentAnimation = ItemAnimations.DROPPED;
        this._state = ItemStates.DROPPED;

    }

    get itemType() {
        return this._itemType;
    }

    get currentFrame() {
        let itemTypeOffset = 0;
        if (this.state === ItemStates.IDLE || this.state === ItemStates.DROPPED) {
            itemTypeOffset = this._itemType * (this.size.x * 2 / this._scale);
        }
        return {
            x: this._currentAnimation.frames[this._currentFrame].x + itemTypeOffset,
            y: this._currentAnimation.frames[this._currentFrame].y,
            w: this._currentAnimation.frames[this._currentFrame].w,
            h: this._currentAnimation.frames[this._currentFrame].h,
        };
    }

    get state() {
        return this._state;
    }

    set state(val) {

        if (this._state === val) {
            return;
        }

        this._state = val;
        this.resetAnimation();
    }

    resetAnimation() {

        if (this._state === ItemStates.DROPPED) {
            this._currentAnimation = ItemAnimations.DROPPED;
        } else {
            this._currentAnimation = ItemAnimations.IDLE;
        }
        this._currentFrame = 0;
        this._intervalCtr = 0;

    }

    update() {

        if (this._intervalCtr === this._currentAnimation.interval) {
            this._intervalCtr = 0;
            this._currentFrame++;
            if (this._currentFrame === this._currentAnimation.frames.length) {
                if (this._currentAnimation.loop) {
                    this._currentFrame = 0;
                } else {
                    this._currentFrame = this._currentAnimation.frames.length - 1;
                }
            }
        }
        this._intervalCtr++;

    }

}