
export default class Collision {

    constructor(goA, goB, axis) {

        this._gameObjectA = goA;
        this._gameObjectB = goB;
        this._axis = axis;

    }

    get axis() {
        return this._axis;
    }

    get gameObjectA() {
        return this._gameObjectA;
    }

    get gameObjectB() {
        return this._gameObjectB;
    }

    containsId(id) {
        if (this.gameObjectA.id === id || this.gameObjectB.id === id) {
            return true;
        }
        return false;
    }

    static equals(collisionA, collisionB) {
        if (collisionB.containsId(collisionA.gameObjectA.id) && collisionB.containsId(collisionA.gameObjectB.id)) {
            return true;
        }
        return false;
    }
}