import Collision from "./collision.js";
import CommandTypes from "./commandTypes.js";
import Item, { ItemStates, ItemTypes, ITEM_SIZE } from "./item.js";
import Pawn, { PawnStates, PAWN_SIZE, JUMP_COOLDOWN_TIME, ATTACK_TIME, ATTACK_RADIUS, ATTACK_COOLDOWN_TIME, DAZE_COOLDOWN_TIME } from "./pawn.js";
import Vector2 from "./vector2.js";

import { TileTypes } from './tileDefinitions.js';
import { TILE_SIZE } from "./gameModel.js";

export const GamePhases = {
    'INTRO': 'intro',
    'GAMEPLAY': 'gameplay',
    'OUTRO': 'outro'
};

export const GRAVITY = 1;

export default class GameController {

    constructor(model, objectsToSimulate, isServer, boardData) {

        this._model = model;
        this._objectsToSimulate = objectsToSimulate;
        this._objectsToTemporarilySimulate = [];

        this._model.phase = GamePhases.INTRO;

        // Server only: setup initial model values from data...
        if (boardData) {

            this._model.tiles = boardData.tiles;
            this._model.islands = this.findMapIslands(this._model.tiles);
            this._model.islandsDict = this.buildIslandsDict(this._model.islands);

            let spawnPoints = [];
            let itemASpawn = null;
            let itemBSpawn = null;
            let itemCSpawn = null;

            for (let y = 0; y < boardData.tiles.length; y++) {

                for (let x = 0; x < boardData.tiles[y].length; x++) {

                    if (boardData.tiles[y][x] === TileTypes.SPAWNPOINT) {
                        spawnPoints.push(new Vector2(x * TILE_SIZE + TILE_SIZE / 2, y * TILE_SIZE + TILE_SIZE / 2));
                    }
                    if (boardData.tiles[y][x] === TileTypes.POWERUP_A) {
                        itemASpawn = new Vector2(x * TILE_SIZE + TILE_SIZE / 2, y * TILE_SIZE + TILE_SIZE / 2);
                    }
                    if (boardData.tiles[y][x] === TileTypes.POWERUP_B) {
                        itemBSpawn = new Vector2(x * TILE_SIZE + TILE_SIZE / 2, y * TILE_SIZE + TILE_SIZE / 2);
                    }
                    if (boardData.tiles[y][x] === TileTypes.POWERUP_C) {
                        itemCSpawn = new Vector2(x * TILE_SIZE + TILE_SIZE / 2, y * TILE_SIZE + TILE_SIZE / 2);
                    }
                    if (boardData.tiles[y][x] === TileTypes.EXIT) {
                        this._model.exitPosition = new Vector2(x * TILE_SIZE + TILE_SIZE / 2, y * TILE_SIZE + TILE_SIZE / 2);
                    }

                }
            }

            this._model.spawnPoints = spawnPoints;

            let gameObjects = [];

            if (itemASpawn) {
                gameObjects.push(new Item('item-a', ItemTypes.GOGGLES, itemASpawn.x - ITEM_SIZE / 2, itemASpawn.y - ITEM_SIZE / 2));
            }
            if (itemBSpawn) {
                gameObjects.push(new Item('item-b', ItemTypes.BOOTS, itemBSpawn.x - ITEM_SIZE / 2, itemBSpawn.y - ITEM_SIZE / 2));
            }
            if (itemCSpawn) {
                gameObjects.push(new Item('item-c', ItemTypes.COMPASS, itemCSpawn.x - ITEM_SIZE / 2, itemCSpawn.y - ITEM_SIZE / 2));
            }

            this._model.gameObjects = gameObjects;
        }

        this._spawnIndex = 0;
        this._dynamicCollisions = [];

        this._pawnsWaitingToSpawn = [];
        this._nextObjects = [];
        this._prevObjects = [];

        this._interpolationTime = 0;
        this._interpolationInterval = 0;

        this._isServer = isServer;
        this._collisionCtr = 0;
        this._maxCollisionsPerFrame = 100;

        this._onPhaseChanged = null;
        this._onPlayAudio = null;
        this._onItemPickedup = null;

        this._debug = false;

    }

    get debug() {
        return this._debug;
    }

    set debug(val) {
        this._debug = val;
    }

    get gameObjects() {
        return this._model.gameObjects;
    }

    set gameObjects(val) {
        this._model.gameObjects = val;
    }

    get pawns() {
        return this._model.gameObjects.filter(go => {
            return go.type === 'pawn';
        });
    }

    get items() {
        return this._model.items;
    }

    get tiles() {
        return this._model.tiles;
    }

    get islands() {
        return this._model.islands;
    }

    get spawnPoints() {
        return this._model.spawnPoints;
    }

    set onPhaseChanged(func) {
        this._onPhaseChanged = func;
    }

    set onPlayAudio(func) {
        this._onPlayAudio = func;
    }

    set onItemPickedUp(func) {
        this._onItemPickedup = func;
    }

    findMapIslands(tiles) {

        let mapIslands = [];

        for (let y = 0; y < tiles.length; y++) {
            for (let x = 0; x < tiles[y].length; x++) {
                if (tiles[y][x] !== TileTypes.UNWALKABLE) {

                    const originV2 = new Vector2(x, y);
                    const islandType = tiles[y][x];
                    let tileExistsInIsland = false;

                    for (let i = 0; i < mapIslands.length; i++) {
                        if (this.tileExistsInArray(originV2, mapIslands[i].tiles)) {
                            tileExistsInIsland = true;
                            break;
                        }
                    }
                    if (!tileExistsInIsland) {

                        let islandTilesV2 = [];
                        let tilesToCheck = [originV2];
                        let compatibleTypes = [islandType];

                        if (islandType === TileTypes.WALKABLE || islandType === TileTypes.SPAWNPOINT || islandType === TileTypes.EXIT || islandType === TileTypes.POWERUP_A || islandType === TileTypes.POWERUP_B || islandType === TileTypes.POWERUP_C) {
                            compatibleTypes = [TileTypes.WALKABLE, TileTypes.SPAWNPOINT, TileTypes.EXIT, TileTypes.POWERUP_A, TileTypes.POWERUP_B, TileTypes.POWERUP_C];
                        }

                        while (tilesToCheck.length > 0) {
                            const tileToCheck = tilesToCheck[0];

                            for (let y2 = 0; y2 < tiles.length; y2++) {
                                for (let x2 = 0; x2 < tiles[y2].length; x2++) {
                                    if (x !== x2 || y !== y2) {
                                        const otherTile = new Vector2(x2, y2);
                                        if (
                                            compatibleTypes.indexOf(tiles[y2][x2]) > -1 &&
                                            this.tilesAreAdjacent(tileToCheck, otherTile) &&
                                            !this.tileExistsInArray(otherTile, tilesToCheck) &&
                                            !this.tileExistsInArray(otherTile, islandTilesV2)
                                        ) {
                                            tilesToCheck.push(otherTile);
                                        }
                                    }
                                }
                            }
                            islandTilesV2.push(tilesToCheck.shift());
                        }

                        let doorsV2 = [];
                        for (let i = 0; i < islandTilesV2.length; i++) {
                            const tileV2 = islandTilesV2[i];

                            if (tileV2.y > 0 && tiles[tileV2.y - 1][tileV2.x] === TileTypes.DOOR) {
                                doorsV2.push(new Vector2(tileV2.x, tileV2.y - 1));
                            }
                            if (tileV2.x < tiles[0].length - 1 && tiles[tileV2.y][tileV2.x + 1] === TileTypes.DOOR) {
                                doorsV2.push(new Vector2(tileV2.x + 1, tileV2.y));
                            }
                            if (tileV2.y < tiles.length - 1 && tiles[tileV2.y + 1][tileV2.x] === TileTypes.DOOR) {
                                doorsV2.push(new Vector2(tileV2.x, tileV2.y + 1));
                            }
                            if (tileV2.x > 0 && tiles[tileV2.y][tileV2.x - 1] === TileTypes.DOOR) {
                                doorsV2.push(new Vector2(tileV2.x - 1, tileV2.y));
                            }
                        }
                        mapIslands.push({
                            id: mapIslands.length,
                            type: islandType,
                            tiles: islandTilesV2,
                            doors: doorsV2,
                        });
                    }
                }
            }
        }

        return mapIslands;
    }

    buildIslandsDict(islands) {

        const islandsDict = {};

        for (let i = 0; i < islands.length; i++) {
            for (let j = 0; j < islands[i].tiles.length; j++) {
                const tile = islands[i].tiles[j];
                if (islandsDict[tile.y] === undefined || islandsDict[tile.y] === null) {
                    islandsDict[tile.y] = {};
                }
                islandsDict[tile.y][tile.x] = islands[i].id;
            }
        }

        return islandsDict;
    }

    resetLocalState(refGameModel, interval, objectsToSimulate) {

        this._model.phase = refGameModel.phase;
        this._objectsToSimulate = objectsToSimulate;

        // Reset local interpolation values...
        this._interpolationTime = 0;
        this._interpolationInterval = interval;

        const gameObjectsCopy = JSON.parse(JSON.stringify(refGameModel.gameObjects));
        this.gameObjects = gameObjectsCopy.map((go) => {
            if (go._type === 'pawn') {
                return Pawn.cast(go);
            }
            if (go._type === 'item') {
                return Item.cast(go);
            }
        });

        // Cache off pawn positions for interpolation...
        // If a pawn has been locally moved, use it's updated position as its previous position...
        this._prevObjects = this._nextObjects.map(nextGO => {
            if (this._objectsToTemporarilySimulate.indexOf(nextGO.id) < 0) {
                return nextGO;
            } else {
                const filteredGOs = this.gameObjects.filter(currentGO => {
                    return nextGO.id === currentGO.id;
                });
                if (filteredGOs.length > 0) {
                    return filteredGOs[0];
                }
                return null;
            }
        });

        this._nextObjects = this.gameObjects.map(go => {
            if (go.type === 'pawn') {
                return Pawn.cast(go);
            }
            if (go.type === 'item') {
                return Item.cast(go);
            }
            return null;
        });

        // Set each non-simulated pawn position to prev position...
        this.gameObjects.forEach(go => {
            if (this._objectsToSimulate.indexOf(go.id) < 0) {
                let goIndex = -1;
                for (let i = 0; i < this._prevObjects.length; i++) {
                    if (this._prevObjects[i].id === go.id) {
                        goIndex = i;
                    }
                }
                if (goIndex > -1) {
                    go.position = this._prevObjects[goIndex].position;
                }
            }
        });

        this._objectsToTemporarilySimulate = [];

    }

    addPlayer(user) {

        this._objectsToSimulate.push(user.id);
        this.addPawn(user);
    }

    removePlayer(userId) {

        this._objectsToSimulate = this._objectsToSimulate.filter(id => {
            return id !== userId;
        });
        this.removePawn(userId);
    }

    addPawn(user) {

        const pawn = new Pawn(user.id, user.index, this.spawnPoints[this._spawnIndex].x - PAWN_SIZE / 2, this.spawnPoints[this._spawnIndex].y - PAWN_SIZE / 2);
        pawn.active = false;

        this._pawnsWaitingToSpawn.push(pawn.id);
        this._model.gameObjects.push(pawn);

        this._spawnIndex++;
        if (this._spawnIndex === this.spawnPoints.length) {
            this._spawnIndex = 0;
        }
    }

    removePawn(userId) {

        this._model.gameObjects = this._model.gameObjects.filter(go => {
            return go.id !== userId;
        });
    }

    update(dt, commands, freezeInterpolation) {

        const frameStartTime = Date.now();

        if (this._model.phase === GamePhases.OUTRO) {
            return;
        }

        if (this._model.phase === GamePhases.INTRO) {
            this._model.phase = GamePhases.GAMEPLAY;
            if (this._onPhaseChanged) {
                this._onPhaseChanged(this._model.phase);
            }
        }

        // Try to activate any newly spawned pawns...
        if (this._pawnsWaitingToSpawn.length > 0 && this._isServer) {

            const activatedPawnIds = [];

            for (let i = 0; i < this._pawnsWaitingToSpawn.length; i++) {

                const pawnId = this._pawnsWaitingToSpawn[i];
                const filteredGos = this.gameObjects.filter(go => {
                    return go.id === pawnId;
                });

                if (filteredGos.length > 0) {

                    const pawn = filteredGos[0];
                    let collisionsFound = false;

                    for (let j = 0; j < this.gameObjects.length; j++) {
                        if (pawn.id === this.gameObjects[j].id) {
                            continue;
                        }
                        if (this.gameObjects[j].type === 'pawn' && this.gameObjectsOverlap(pawn, this.gameObjects[j])) {
                            collisionsFound = true;
                            break;
                        }
                    }
                    if (!collisionsFound) {
                        pawn.active = true;
                        activatedPawnIds.push(pawnId);
                    }
                }
            }

            let updatedQueue = [];
            for (let i = 0; i < this._pawnsWaitingToSpawn.length; i++) {
                if (activatedPawnIds.indexOf(this._pawnsWaitingToSpawn[i]) < 0) {
                    updatedQueue.push(this._pawnsWaitingToSpawn[i]);
                }
            }
            this._pawnsWaitingToSpawn = updatedQueue;
        }

        for (let i = 0; i < this.gameObjects.length; i++) {

            // If this gameObject is simulated...
            if (this._objectsToSimulate.indexOf(this.gameObjects[i].id) > -1 || this._objectsToTemporarilySimulate.indexOf(this.gameObjects[i].id) > -1 || (this.gameObjects[i].type === 'item' && this._isServer)) {

                // Reset pawn idle state...
                if (this.gameObjects[i].type === 'pawn' && this.gameObjects[i].state === PawnStates.WALK) {
                    if (this.gameObjects[i].walkCooldown === 0) {
                        this.gameObjects[i].state = PawnStates.IDLE;
                    }
                    if (this.gameObjects[i].walkCooldown > 0) {
                        this.gameObjects[i].walkCooldown--;
                    }
                }

                // Update gravity...
                this.gameObjects[i].velY += GRAVITY;

                // Apply friction...
                if (this.gameObjects[i].velX > 0) {
                    this.gameObjects[i].velX -= this.gameObjects[i].friction;
                    if (this.gameObjects[i].velX < 0) {
                        this.gameObjects[i].velX = 0;
                        if (this.gameObjects[i].type === 'pawn' && this.gameObjects[i].state !== PawnStates.ATTACK && this.gameObjects[i].state !== PawnStates.DAZED) {
                            this.gameObjects[i].state = PawnStates.IDLE;
                        }
                    }
                }
                if (this.gameObjects[i].velX < 0) {
                    this.gameObjects[i].velX += this.gameObjects[i].friction;
                    if (this.gameObjects[i].velX > 0) {
                        this.gameObjects[i].velX = 0;
                        if (this.gameObjects[i].type === 'pawn' && this.gameObjects[i].state !== PawnStates.ATTACK && this.gameObjects[i].state !== PawnStates.DAZED) {
                            this.gameObjects[i].state = PawnStates.IDLE;
                        }
                    }
                }

                // Pawn specific...
                if (this.gameObjects[i].type === 'pawn') {

                    // Update pawn timers and cooldowns...
                    if (this.gameObjects[i].state === PawnStates.ATTACK) {
                        if (this.gameObjects[i].attackTime > 0) {
                            this.gameObjects[i].attackTime--;
                            if (this.gameObjects[i].attackTime === 0) {
                                this.gameObjects[i].state = PawnStates.IDLE;
                            }
                        }
                    }
                    if (this.gameObjects[i].state === PawnStates.DAZED) {
                        if (this.gameObjects[i].dazeCooldown > 0) {
                            this.gameObjects[i].dazeCooldown--;
                            if (this.gameObjects[i].dazeCooldown === 0) {
                                this.gameObjects[i].state = PawnStates.IDLE;
                            }
                        }
                    }
                    if (this.gameObjects[i].jumpCooldown > 0) {
                        this.gameObjects[i].jumpCooldown--;
                    }
                    if (this.gameObjects[i].attackCooldown > 0) {
                        this.gameObjects[i].attackCooldown--;
                    }

                    // Apply impulses/commands...
                    for (let j = 0; j < commands.length; j++) {
                        let thePawn = null;
                        if (this.gameObjects[i].id === commands[j].userId) {
                            thePawn = this.gameObjects[i];
                        }
                        if (thePawn && thePawn.state !== PawnStates.DAZED && thePawn.state !== PawnStates.ATTACK) {

                            if (commands[j].type === CommandTypes.LEFT) {
                                thePawn.velX = -thePawn.speed;
                                thePawn.orientation = 'left';
                                if (thePawn.state !== PawnStates.FALL && thePawn.state !== PawnStates.JUMP && thePawn.state !== PawnStates.DOUBLE_JUMP) {
                                    thePawn.state = PawnStates.WALK;
                                    thePawn.walkCooldown = 1;
                                    if (this._onPlayAudio) {
                                        this._onPlayAudio('walk');
                                    }
                                }
                            }
                            if (commands[j].type === CommandTypes.RIGHT) {
                                thePawn.velX = thePawn.speed;
                                thePawn.orientation = 'right';
                                if (thePawn.state !== PawnStates.FALL && thePawn.state !== PawnStates.JUMP && thePawn.state !== PawnStates.DOUBLE_JUMP) {
                                    thePawn.state = PawnStates.WALK;
                                    thePawn.walkCooldown = 1;
                                    if (this._onPlayAudio) {
                                        this._onPlayAudio('walk');
                                    }
                                }
                            }

                            if (commands[j].type === CommandTypes.JUMP && thePawn.jumpCooldown === 0) {
                                if (thePawn.state === PawnStates.JUMP && thePawn.items.indexOf(ItemTypes.BOOTS) > - 1) {
                                    thePawn.state = PawnStates.DOUBLE_JUMP;
                                    thePawn.velY = -thePawn.jumpHeight;
                                    thePawn.jumpCooldown = JUMP_COOLDOWN_TIME;
                                    if (this._onPlayAudio) {
                                        this._onPlayAudio('jump');
                                    }
                                } else if (thePawn.state === PawnStates.IDLE || thePawn.state === PawnStates.WALK || thePawn.state === PawnStates.FALL) {
                                    thePawn.state = PawnStates.JUMP;
                                    thePawn.velY = -thePawn.jumpHeight;
                                    thePawn.jumpCooldown = JUMP_COOLDOWN_TIME;
                                    if (this._onPlayAudio) {
                                        this._onPlayAudio('jump');
                                    }
                                }
                            }

                            if (commands[j].type === CommandTypes.ATTACK && thePawn.state !== PawnStates.ATTACK && thePawn.attackCooldown === 0) {

                                thePawn.state = PawnStates.ATTACK;
                                thePawn.attackTime = ATTACK_TIME;
                                thePawn.attackCooldown = ATTACK_COOLDOWN_TIME;

                                if (this._isServer) {

                                    for (let k = 0; k < this.gameObjects.length; k++) {
                                        if (this.gameObjects[k].type === 'pawn' && this.gameObjects[k].state !== PawnStates.DAZED && this.gameObjects[k].id !== commands[j].userId) {

                                            const otherPawn = this.gameObjects[k];

                                            if (otherPawn.position.x >= thePawn.position.x - ATTACK_RADIUS && otherPawn.position.x < thePawn.position.x + ATTACK_RADIUS) {
                                                if (otherPawn.position.y >= thePawn.position.y - ATTACK_RADIUS && otherPawn.position.y < thePawn.position.y + ATTACK_RADIUS) {

                                                    otherPawn.state = PawnStates.DAZED;
                                                    otherPawn.dazeCooldown = DAZE_COOLDOWN_TIME;

                                                    // Dazed pawn drops items...
                                                    const droppedItemKeys = otherPawn.items;
                                                    const dropForce = 2.5;

                                                    for (let l = 0; l < droppedItemKeys.length; l++) {
                                                        for (let m = 0; m < this.gameObjects.length; m++) {
                                                            if (this.gameObjects[m].type === 'item' && this.gameObjects[m].itemType === droppedItemKeys[l]) {
                                                                this.gameObjects[m].position = otherPawn.position;
                                                                this.gameObjects[m].active = true;
                                                                this.gameObjects[m].velY = -dropForce * 4;
                                                                this.gameObjects[m].velX = droppedItemKeys.length > 1 ? (l * (dropForce / (droppedItemKeys.length - 1))) - dropForce / 2 : 0;
                                                                this.gameObjects[m].state = ItemStates.DROPPED;
                                                                break;
                                                            }
                                                        }
                                                    }
                                                    otherPawn.dropAllItems();
                                                }
                                            }
                                        }
                                    }
                                } else {

                                    if (this._onPlayAudio) {
                                        this._onPlayAudio('buzz');
                                    }

                                }
                            }

                            if (commands[j].type === CommandTypes.DEBUG && this._isServer) {

                                const droppedItemKeys = thePawn.items;
                                const dropForce = 10;

                                for (let j = 0; j < droppedItemKeys.length; j++) {
                                    for (let k = 0; k < this.gameObjects.length; k++) {
                                        if (this.gameObjects[k].type === 'item' && this.gameObjects[k].itemType === droppedItemKeys[j]) {
                                            this.gameObjects[k].position = thePawn.position;
                                            this.gameObjects[k].active = true;
                                            this.gameObjects[k].velY = -dropForce;
                                            this.gameObjects[k].velX = droppedItemKeys.length > 1 ? (j * (dropForce / (droppedItemKeys.length - 1))) - dropForce / 2 : 0;
                                            this.gameObjects[k].state = ItemStates.DROPPED;
                                            break;
                                        }
                                    }
                                }
                                thePawn.dropAllItems();
                            }
                        }
                    }
                }

                // Set GO current island...
                if (!isNaN(this.gameObjects[i].position.x) && !isNaN(this.gameObjects[i].position.y)) {
                    let goCenterPoint = new Vector2(0, 0);
                    let currentTile = new Vector2(0, 0);

                    goCenterPoint.x = this.gameObjects[i].position.x + this.gameObjects[i].size.x / 2;
                    goCenterPoint.y = this.gameObjects[i].position.y + this.gameObjects[i].size.y / 2;

                    currentTile.x = Math.floor(goCenterPoint.x / TILE_SIZE);
                    currentTile.y = Math.floor(goCenterPoint.y / TILE_SIZE);

                    this.gameObjects[i].currentIslandIndex = this._model.islandsDict[currentTile.y][currentTile.x];
                } else {
                    console.log('@GameController: gameObject position undefined: ', this.gameObjects[i]);
                }

                // Cap all velocity except upward to allow for jumps...
                if (this.gameObjects[i].velX > 10) {
                    this.gameObjects[i].velX = 10;
                }
                if (this.gameObjects[i].velX < -10) {
                    this.gameObjects[i].velX = -10;
                }
                if (this.gameObjects[i].velY > 10) {
                    this.gameObjects[i].velY = 10;
                }

            } else if (this._nextObjects.length === this._prevObjects.length) {
                // Interpolated objects...
                const prevFiltered = this._prevObjects.filter(go => {
                    return go.id === this.gameObjects[i].id;
                });
                const nextFiltered = this._nextObjects.filter(go => {
                    return go.id === this.gameObjects[i].id;
                });
                if (prevFiltered.length > 0 && nextFiltered.length > 0) {
                    const pos = Vector2.lerp(
                        prevFiltered[0].position,
                        nextFiltered[0].position,
                        this._interpolationTime,
                        this._interpolationInterval,
                    );
                    this.gameObjects[i].velocity = new Vector2(
                        pos.x - this.gameObjects[i].position.x,
                        pos.y - this.gameObjects[i].position.y,
                    );
                }
            }
        }

        // Determine frozen axes for each pawn based on static collisions (tiles) before resolving dynamic collisions...
        this._collisionCtr = 0;
        this.checkForStaticCollisions();
        this.checkForDynamicCollisions();

        for (let i = 0; i < this.gameObjects.length; i++) {

            if (this.gameObjects[i].active) {
                // Calculate new positions...
                this.gameObjects[i].position = Vector2.add(this.gameObjects[i].position, this.gameObjects[i].velocity);
                this.gameObjects[i].x = Math.floor(this.gameObjects[i].x);
                this.gameObjects[i].y = Math.floor(this.gameObjects[i].y);

                // All collisions calculated, unfreeze axes...
                this.gameObjects[i].unfreezeAxes();
            } else if (this.gameObjects[i].type === 'item') {

                // Move inactive items with their associated pawns...
                for (let j = 0; j < this.gameObjects.length; j++) {
                    if (i === j) {
                        continue;
                    }
                    const itemKey = this.gameObjects[i].itemType;
                    if (this.gameObjects[j].type === 'pawn') {
                        if (this.gameObjects[j].items.indexOf(itemKey) > -1) {
                            this.gameObjects[i].position = this.gameObjects[j].position;
                        }
                    }
                }
            }

            this.gameObjects[i].update();
        }

        // Update game time...
        this._model.time++;
        if (!freezeInterpolation && this._interpolationTime < this._interpolationInterval) {
            this._interpolationTime++;
        }

        if (this._debug) {
            console.log('@Debug: GameController: update time: ' + (Date.now() - frameStartTime));
        }

    }

    checkForStaticCollisions() {

        let go = null;

        for (let i = 0; i < this.gameObjects.length; i++) {

            go = this.gameObjects[i];

            const currTilePosTopLft = this.tileAddressToWorldSpace(this.pointToTileAddress(go.left, go.top));
            const currTilePosTopRgt = this.tileAddressToWorldSpace(this.pointToTileAddress(go.right, go.top));
            const currTilePosBtmLft = this.tileAddressToWorldSpace(this.pointToTileAddress(go.left, go.bottom));
            const currTilePosBtmRgt = this.tileAddressToWorldSpace(this.pointToTileAddress(go.right, go.bottom));

            // Left
            if (go.velX < 0) {

                const nextTileTypeTopLft = this.getTileTypeFromPoint(go.left + go.velX, go.top);
                const nextTileTypeBtmLft = this.getTileTypeFromPoint(go.left + go.velX, go.bottom);

                if (nextTileTypeTopLft === TileTypes.EXIT || nextTileTypeBtmLft === TileTypes.EXIT) {
                    this.onExitReached(go);
                }
                else if (nextTileTypeTopLft === TileTypes.UNWALKABLE || nextTileTypeBtmLft === TileTypes.UNWALKABLE) {
                    go.velX = -(go.x - currTilePosTopLft.x);
                    go.frozenX = true;
                }

            }

            // Right
            if (go.velX > 0) {

                const nextTileTypeTopRgt = this.getTileTypeFromPoint(go.right + go.velX, go.top);
                const nextTileTypeBtmRgt = this.getTileTypeFromPoint(go.right + go.velX, go.bottom);

                if (nextTileTypeTopRgt === TileTypes.EXIT || nextTileTypeBtmRgt === TileTypes.EXIT) {
                    this.onExitReached(go);
                }
                else if (nextTileTypeTopRgt === TileTypes.UNWALKABLE || nextTileTypeBtmRgt === TileTypes.UNWALKABLE) {
                    go.velX = ((currTilePosTopRgt.x + TILE_SIZE) - (go.x + go.size.x));
                    go.frozenX = true;
                }

            }

            // Up
            if (go.velY < 0) {

                const nextTileTypeTopLft = this.getTileTypeFromPoint(go.left, go.top + go.velY);
                const nextTileTypeTopRgt = this.getTileTypeFromPoint(go.right, go.top + go.velY);

                if (nextTileTypeTopLft === TileTypes.EXIT || nextTileTypeTopRgt === TileTypes.EXIT) {
                    this.onExitReached(go);
                }
                else if (nextTileTypeTopLft === TileTypes.UNWALKABLE || nextTileTypeTopRgt === TileTypes.UNWALKABLE) {
                    go.velY = -(go.y - currTilePosTopLft.y);
                    go.frozenY = true;
                }

            }

            // Down
            if (go.velY > 0) {

                const nextTileTypeBtmLft = this.getTileTypeFromPoint(go.left, go.bottom + go.velY);
                const nextTileTypeBtmRgt = this.getTileTypeFromPoint(go.right, go.bottom + go.velY);

                if (nextTileTypeBtmLft === TileTypes.EXIT || nextTileTypeBtmRgt === TileTypes.EXIT) {
                    this.onExitReached(go);
                }
                // Falling...
                else if (nextTileTypeBtmLft === TileTypes.WALKABLE && nextTileTypeBtmRgt === TileTypes.WALKABLE) {
                    if (go.type === 'pawn') {
                        if (go.state !== PawnStates.ATTACK && go.state !== PawnStates.JUMP && go.state !== PawnStates.DOUBLE_JUMP && go.state !== PawnStates.DAZED) {
                            go.state = PawnStates.FALL;
                        }
                    }
                }
                // Hit the ground...
                else if (nextTileTypeBtmLft === TileTypes.UNWALKABLE || nextTileTypeBtmRgt === TileTypes.UNWALKABLE) {
                    go.velY = (currTilePosBtmLft.y + TILE_SIZE) - go.y - go.size.y;
                    go.frozenY = true;
                    if (go.type === 'pawn') {
                        if (go.state === PawnStates.FALL || go.state === PawnStates.JUMP || go.state === PawnStates.DOUBLE_JUMP) {
                            go.state = PawnStates.IDLE;
                        }
                    }
                }

            }

            // Diagonals...

            // Top right
            if (go.velX > 0 && go.velY < 0) {
                const nextTileTypeTopRgt = this.getTileTypeFromPoint(go.right + go.velX, go.top + go.velY);

                if (nextTileTypeTopRgt === TileTypes.EXIT) {
                    this.onExitReached(go);
                }
                else if (nextTileTypeTopRgt === TileTypes.UNWALKABLE) {
                    go.velX = (currTilePosTopRgt.x + TILE_SIZE) - (go.x + go.size.x);
                    go.velY = -(go.y - currTilePosTopRgt.y);
                    go.frozenX = true;
                    go.frozenY = true;
                }
            }

            // Top left
            if (go.velX < 0 && go.velY < 0) {
                const nextTileTypeTopLft = this.getTileTypeFromPoint(go.left + go.velX, go.top + go.velY);

                if (nextTileTypeTopLft === TileTypes.EXIT) {
                    this.onExitReached(go);
                }
                else if (nextTileTypeTopLft === TileTypes.UNWALKABLE) {
                    go.velX = go.x - currTilePosTopLft.x;
                    go.velY = -(go.y - currTilePosTopLft.y);
                    go.frozenX = true;
                    go.frozenY = true;
                }
            }

            // Bottom left
            if (go.velX < 0 && go.velY > 0) {
                const nextTileTypeBtmLft = this.getTileTypeFromPoint(go.left + go.velX, go.bottom + go.velY);

                if (nextTileTypeBtmLft === TileTypes.EXIT) {
                    this.onExitReached(go);
                }
                else if (nextTileTypeBtmLft === TileTypes.UNWALKABLE) {
                    go.velX = go.x - currTilePosBtmLft.x;
                    go.velY = (currTilePosBtmLft.y + TILE_SIZE) - (go.position.y + go.size.y) + 1;
                    go.frozenX = true;
                    go.frozenY = true;
                }
            }

            // Bottom right
            if (go.velX > 0 && go.velY > 0) {
                const nextTileTypeBtmRgt = this.getTileTypeFromPoint(go.right + go.velX, go.bottom + go.velY);

                if (nextTileTypeBtmRgt === TileTypes.EXIT) {
                    this.onExitReached(go);
                }
                else if (nextTileTypeBtmRgt === TileTypes.UNWALKABLE) {
                    go.velX = (currTilePosBtmRgt.x + TILE_SIZE) - (go.x + go.size.x);
                    go.velY = (currTilePosBtmRgt.y + TILE_SIZE) - (go.position.y + go.size.y) + 1;
                    go.frozenX = true;
                    go.frozenY = true;
                }
            }
        }

    }

    checkForDynamicCollisions() {

        if (this.gameObjects.length < 2) {
            return;
        }

        this._dynamicCollisions = [];

        let gameObject = null;
        let otherGameObject = null;

        for (let i = 0; i < this.gameObjects.length; i++) {

            gameObject = this.gameObjects[i];
            for (let j = 0; j < this.gameObjects.length; j++) {

                otherGameObject = this.gameObjects[j];
                if (otherGameObject.id !== gameObject.id) {

                    const collision = this.checkForDynamicCollision(gameObject, otherGameObject);
                    if (collision !== null && !this.collisionExists(collision)) {
                        this._dynamicCollisions.push(collision);
                    }
                }
            }
        }

        if (this._dynamicCollisions.length === 0 || this._collisionCtr >= this._maxCollisionsPerFrame) {
            return;
        }
        this._collisionCtr++;

        for (let i = 0; i < this._dynamicCollisions.length; i++) {
            this.tryResolveCollision(this._dynamicCollisions[i]);
        }

        this.checkForStaticCollisions();
        this.checkForDynamicCollisions();
    }

    checkForDynamicCollision(goA, goB) {

        if (goA.type === 'item' && goA.state === ItemStates.DROPPED) {
            return null;
        }

        if (goB.type === 'item' && goB.state === ItemStates.DROPPED) {
            return null;
        }

        if (!goA.active || !goB.active) {
            return null;
        }

        let axis = '';

        if (goA.position.x + goA.velX + goA.size.x > goB.position.x + goB.velX &&
            goA.position.x + goA.velX < goB.position.x + goB.velX + goB.size.x) {


            if (goA.position.y + goA.velY + goA.size.y > goB.position.y + goB.velY &&
                goA.position.y + goA.velY < goB.position.y + goB.velY + goB.size.y) {

                // If there wasn't previously an overlap on the x axis, there is a collision on the x axis...
                if (goA.position.x + goA.size.x <= goB.position.x ||
                    goA.position.x > goB.position.x + goB.size.x) {
                    axis += 'x';
                }

                // If there wasn't previously an overlap on the y axis, there is a collision on the y axis
                if (goA.position.y + goA.size.y <= goB.position.y ||
                    goA.position.y > goB.position.y + goB.size.y) {
                    axis += 'y';
                }
            }
        }

        if (axis !== '') {
            return new Collision(goA, goB, axis);
        }

        return null;
    }

    tryResolveCollision(collision) {

        if (collision.gameObjectA.type === 'item' && collision.gameObjectB.type === 'pawn') {
            if (collision.gameObjectA.state === ItemStates.IDLE) {
                collision.gameObjectA.active = false;
                collision.gameObjectB.pickupItem(collision.gameObjectA.itemType);
                if (this._onItemPickedup) {
                    this._onItemPickedup(collision.gameObjectA, collision.gameObjectB);
                }
            }
            return;
        }

        if (collision.gameObjectA.type === 'pawn' && collision.gameObjectB.type === 'item') {
            if (collision.gameObjectB.state === ItemStates.IDLE) {
                collision.gameObjectB.active = false;
                collision.gameObjectA.pickupItem(collision.gameObjectB.itemType);
                if (this._onItemPickedup) {
                    this._onItemPickedup(collision.gameObjectB, collision.gameObjectA);
                }
            }
            return;
        }

        if (collision.gameObjectA.type === 'pawn' && collision.gameObjectB.type === 'pawn') {
            if (this._objectsToSimulate.indexOf(collision.gameObjectA.id) > -1 && this._objectsToSimulate.indexOf(collision.gameObjectB.id) < 0) {
                if (this._objectsToTemporarilySimulate.indexOf(collision.gameObjectB.id) < 0) {
                    this._objectsToTemporarilySimulate.push(collision.gameObjectB.id);
                }

            } else if (this._objectsToSimulate.indexOf(collision.gameObjectA.id) < 0 && this._objectsToSimulate.indexOf(collision.gameObjectB.id) > -1) {
                if (this._objectsToTemporarilySimulate.indexOf(collision.gameObjectA.id) < 0) {
                    this._objectsToTemporarilySimulate.push(collision.gameObjectA.id);
                }
            }
        }

        if (collision.axis === 'x') {

            let pawnLeft = collision.gameObjectA;
            let pawnRight = collision.gameObjectB;

            if (collision.gameObjectB.position.x < collision.gameObjectA.position.x) {
                pawnLeft = collision.gameObjectB;
                pawnRight = collision.gameObjectA;
            }

            if (collision.gameObjectA.frozenX && collision.gameObjectB.frozenX) {
                return;
            } else if (collision.gameObjectA.frozenX || collision.gameObjectB.frozenX) {
                // find out of the static pawn is to the right or left of the dynamic pawn
                // adjust position of dynamic pawn relative to the static one
                // mark dynamic pawn as static

                if (pawnLeft.frozenX) {
                    pawnRight.velX = pawnLeft.x + pawnLeft.size.x - pawnRight.x;
                    pawnRight.frozenX = true;
                }
                else if (pawnRight.frozenX) {
                    pawnLeft.velX = pawnRight.x - pawnLeft.x - pawnRight.size.x;
                    pawnLeft.frozenX = true;
                }

                return;

            } else {

                const velX = (pawnLeft.velX + pawnRight.velX) / 2;
                pawnLeft.velX = velX;
                pawnRight.velX = velX;

            }
        }

        else if (collision.axis === 'y') {

            let pawnTop = collision.gameObjectA;
            let pawnBottom = collision.gameObjectB;

            if (collision.gameObjectB.position.y < collision.gameObjectA.position.y) {
                pawnTop = collision.gameObjectB;
                pawnBottom = collision.gameObjectA;
            }

            if (collision.gameObjectA.frozenY && collision.gameObjectB.frozenY) {
                return;
            } else if (pawnBottom.frozenY) {

                // Only solving for pawnBottom because gravity.
                pawnTop.velY = -((pawnTop.y + pawnTop.size.y) - pawnBottom.y);
                pawnTop.frozenY = true;
                if (pawnTop.state !== PawnStates.DAZED && pawnTop.state !== PawnStates.ATTACK) {
                    pawnTop.state = PawnStates.IDLE;
                }

                return;

            } else {

                const velY = (pawnTop.velY + pawnBottom.velY) / 2;
                pawnTop.velY = velY;
                pawnBottom.velY = velY;

            }
        }

    }

    onExitReached(go) {
        if (go.type === 'pawn' && this._isServer) {
            this._model.phase = GamePhases.OUTRO;
            this._model.winner = go.id;
            if (this._onPhaseChanged) {
                console.log('game winner: ' + this._model.winner);
                this._onPhaseChanged(this._model.phase);
            }
        }
    }

    collisionExists(collision) {
        for (let i = 0; i < this._dynamicCollisions.length; i++) {
            if (Collision.equals(collision, this._dynamicCollisions[i])) {
                return true;
            }
        }
        return false;
    }

    pointToTileAddress(x, y) {
        return new Vector2(Math.floor(x / TILE_SIZE), Math.floor(y / TILE_SIZE));
    }

    tileAddressToWorldSpace(v2) {
        v2.x = v2.x * TILE_SIZE;
        v2.y = v2.y * TILE_SIZE;
        return v2;
    }

    getTileTypeFromPoint(x, y) {
        const tileAddress = this.pointToTileAddress(x, y);
        if (tileAddress.y < this.tiles.length && tileAddress.x < this.tiles[0].length) {
            return this.tiles[tileAddress.y][tileAddress.x];
        }
        return TileTypes.NULL;
    }

    tileExistsInArray(tileV2, arr) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].x === tileV2.x && arr[i].y === tileV2.y) {
                return true;
            }
        }
        return false;
    }

    tilesAreAdjacent(tileAV2, tileBV2) {
        if (tileAV2.x === tileBV2.x) {
            if (tileAV2.y + 1 === tileBV2.y || tileAV2.y - 1 === tileBV2.y) {
                return true;
            }
        }
        if (tileAV2.y === tileBV2.y) {
            if (tileAV2.x + 1 === tileBV2.x || tileAV2.x - 1 === tileBV2.x) {
                return true;
            }
        }
        return false;
    }

    gameObjectsOverlap(goA, goB) {
        // This is different from a collision, where a collision takes into account game object velocity...
        if (goA.position.x + goA.size.x > goB.position.x &&
            goA.position.x < goB.position.x + goB.size.x) {
            if (goA.position.y + goA.size.y > goB.position.y &&
                goA.position.y < goB.position.y + goB.size.y) {
                return true;
            }
        }
        return false;
    }
}