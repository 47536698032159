
export const AudioEffects = {
    'BUZZ': 'buzz',
    'CLICK': 'click',
    'DING': 'ding',
    'EXIT': 'exit',
    'JUMP': 'jump',
    'METER_FULL': 'meter-full',
    'MUSIC': 'music',
    'POWERUP': 'powerup',
    'SWITCH': 'switch',
    'WALK': 'walk',
};

export const DefaultVolumeMappings = {
    'BUZZ': .4,
    'CLICK': .3,
    'DING': .4,
    'EXIT': .5,
    'JUMP': .5,
    'METER_FULL': .5,
    'MUSIC': .35,
    'POWERUP': .3,
    'SWITCH': .3,
    'WALK': .35,
};

export default class AudioManager {

    constructor() {

        this._channels = {};
        this._volumeMappings = {};
        this._volumeMultiplier = 0;
        this.buildVolumeMappings();
        this.createChannels();

        /*
        channels:
        {
            0: {
                buzz: Audio(),
            }
        }
        */
    }

    get volume() {

        return this._volumeMultiplier;

    }

    set volume(val) {

        this._volumeMultiplier = val;
        // Reset music volume...
        this._channels[-1][AudioEffects.MUSIC].volume = this._volumeMappings[AudioEffects.MUSIC] * this._volumeMultiplier;

    }

    buildVolumeMappings() {

        const audioKeys = Object.keys(AudioEffects);
        for (let i = 0; i < audioKeys.length; i++) {
            const key = AudioEffects[audioKeys[i]];
            this._volumeMappings[key] = DefaultVolumeMappings[audioKeys[i]];
        }

    }

    createChannels() {

        const numPlayerChannels = 4;
        for (let i = -1; i < numPlayerChannels; i++) {
            this._channels[i] = this.createChannel();
        }

    }

    createChannel() {

        const channel = {};
        const audioKeys = Object.keys(AudioEffects);
        for (let i = 0; i < audioKeys.length; i++) {
            const clipName = AudioEffects[audioKeys[i]];
            channel[clipName] = new Audio(require('../assets/audio/' + clipName + '.mp3'), 1.0)
        }
        return channel;

    }

    play(clip, channel, dynamicVolume) {

        // console.log('$$$ AudioManager: play clip: ' + clip + ', channel: ' + channel + ', volume: ' + volume);
        const audio = this._channels[channel][clip];
        audio.volume = dynamicVolume * this._volumeMappings[clip] * this._volumeMultiplier;
        audio.loop = false;
        audio.play();

    }

    startLoop(clip, channel, dynamicVolume) {

        // console.log('$$$ AudioManager: start loop: ' + clip + ', channel: ' + channel + ', volume: ' + volume);
        const audio = this._channels[channel][clip];
        audio.volume = dynamicVolume * this._volumeMappings[clip] * this._volumeMultiplier;
        audio.loop = true;
        audio.play();

    }

    stopLoop(clip, channel, dynamicVolume) {

        // console.log('$$$ AudioManager: stop loop: ' + clip + ', channel: ' + channel + ', volume: ' + volume);
        const audio = this._channels[channel][clip];
        audio.volume = dynamicVolume * this._volumeMappings[clip] * this._volumeMultiplier;
        audio.loop = false;
        audio.pause();

    }

}