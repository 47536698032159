
export const FRAME_RATE = 45; // Frames per sec..
const UPDATES_PER_SECOND = 7; // Updates per sec...
const UPDATE_INTERVAL = Math.floor(FRAME_RATE / UPDATES_PER_SECOND); // Number of frames between updates to the client...

import Command from './command.js';
import GameModel from './gameModel.js';
import GameController, { GamePhases } from './gameController.js';
import Item from './item.js';
import Pawn from './pawn.js';
import StateUpdate from './stateUpdate.js';

export default class Game {

    constructor(boardData, users) {

        console.log('new game: ' + users);

        this._users = users;
        this._model = new GameModel();
        this._prevModel = null;

        this._controller = new GameController(this._model, this._users.map(user => { return user.id }), true, boardData);
        this._controller.onPhaseChanged = (phase) => {
            const phaseState = new StateUpdate();
            phaseState.setValue('phase', phase);
            if (phase === GamePhases.OUTRO) {
                phaseState.setValue('winner', this._model.winner);
            }
            this._clientStateSetter(phaseState);
        };

        this._clientStateSetter = null;
        this._stateUpdateCounter = 0;
        this._gameLoop = null;
        this._forceFullUpdate = false;

        this._unprocessedCommands = [];
        this._processedCommands = {};

    }

    get model() {
        return this._model;
    }

    set clientStateSetter(callback) {
        this._clientStateSetter = callback;
    }

    start() {

        const initialState = new StateUpdate();
        initialState.setValue('tiles', this._model.tiles);
        initialState.setValue('islands', this._model.islands);
        initialState.setValue('islandsDict', this._model.islandsDict);
        initialState.setValue('exitPosition', this._model.exitPosition);
        initialState.setValue('phase', this._model.phase);
        this._clientStateSetter(initialState);

        for (let i = 0; i < this._users.length; i++) {
            this._controller.addPawn(this._users[i]);
        }

        this._gameLoop = setInterval((dt) => {

            // Commands from the client have a future timestamp, find the commands to be executed at this frame
            // Maintain the commands to be executed at future frames
            const nextFrame = this.model.time + 1;
            let commandsToProcess = [];
            let commandsInWaiting = [];
            for (let i = 0; i < this._unprocessedCommands.length; i++) {
                if (this._unprocessedCommands[i].time <= nextFrame) {
                    commandsToProcess.push(this._unprocessedCommands[i]);
                } else {
                    commandsInWaiting.push(this._unprocessedCommands[i]);
                }
            }

            // Process commands
            this._controller.update(dt, commandsToProcess, false);

            // Send a dict of processedCommands to the client:
            // Key: userId
            // Value: last processed user commandId
            for (let i = 0; i < commandsToProcess.length; i++) {
                this._processedCommands[commandsToProcess[i].userId] = commandsToProcess[i].id;
            }
            this._unprocessedCommands = commandsInWaiting;

            this._stateUpdateCounter++;
            if (this._stateUpdateCounter >= UPDATE_INTERVAL) {
                this._stateUpdateCounter = 0;

                const gameState = new StateUpdate();
                gameState.setValue('time', this._model.time);
                gameState.setValue('processedCommands', this._processedCommands);
                if (this._forceFullUpdate) {
                    gameState.setValue('gameObjects', this._model.gameObjects);
                    this._forceFullUpdate = false;
                } else {
                    gameState.setValue('gameObjectsMinified', this.minifyGameObjects());
                }

                this._clientStateSetter(gameState);
                this._processedCommands = {};

                this._prevModel = GameModel.copy(this._model);
            }

        }, 1000 / FRAME_RATE);
    }

    end() {
        console.log('game: end');
        clearInterval(this._gameLoop);
    }

    addPlayer(user) {
        this._controller.addPlayer(user);
    }

    removePlayer(userId) {
        this._controller.removePlayer(userId);
        this._forceFullUpdate = true;
    }

    addCommandsToQueue(commandObjs) {
        const commands = commandObjs.map(obj => {
            return Command.cast(obj);
        });
        this._unprocessedCommands = this._unprocessedCommands.concat(commands);
    }

    minifyGameObjects() {
        let minified = [];
        for (let i = 0; i < this._model.gameObjects.length; i++) {
            let currGO = this._model.gameObjects[i];
            let prevGO = null;
            if (this._prevModel) {
                for (let j = 0; j < this._prevModel.gameObjects.length; j++) {
                    if (this._prevModel.gameObjects[j].id === this._model.gameObjects[i].id) {
                        prevGO = this._prevModel.gameObjects[j];
                        break;
                    }
                }
            }
            let delta = null;
            if (currGO.type === 'pawn') {
                delta = Pawn.generateMinifiedDelta(currGO, prevGO);
            }
            if (currGO.type === 'item') {
                delta = Item.generateMinifiedDelta(currGO, prevGO);
            }
            if (Object.keys(delta).length > 0) {
                minified.push(delta);
            }
        }
        return minified;
    }

}