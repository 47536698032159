import Vector2 from './vector2.js';

export const SpriteTypes = {
    'PAWN': 'pawn',
    'ITEM': 'item',
    'EFFECT': 'effect',
}

export default class SpriteView {

    constructor(id, x, y, w, h, type, scale) {

        this._id = id;
        this._type = type;
        this._active = true;
        this._size = new Vector2(w, h);
        this._position = new Vector2(x, y);
        this._currentIslandIndex = -1;
        this._orientation = 'left';
        this._scale = scale;

        this._canvas = document.createElement('canvas');
        this._canvas.width = this._size.x;
        this._canvas.height = this._size.y;
        this._context = this._canvas.getContext('2d');
        this._context.scale(1 / this._scale, 1 / this._scale);
    }

    get id() {
        return this._id;
    }

    get type() {
        return this._type;
    }

    get canvas() {
        return this._canvas;
    }

    get context() {
        return this._context;
    }

    get active() {
        return this._active;
    }

    set active(val) {
        this._active = val;
    }

    get size() {
        return this._size;
    }

    get position() {
        return this._position;
    }

    set position(val) {
        this._position = val;
    }

    get currentIslandIndex() {
        return this._currentIslandIndex;
    }

    set currentIslandIndex(val) {
        this._currentIslandIndex = val;
    }

    get orientation() {
        return this._orientation;
    }

    set orientation(val) {
        this._orientation = val;
    }

    get x() {
        return this._position.x;
    }

    set x(val) {
        this._position.x = val;
    }

    get y() {
        return this._position.y;
    }

    set y(val) {
        this._position.y = val;
    }

    get currentFrame() {
        return {
            x: 0,
            y: 0,
            w: this._size.x,
            h: this._size.y,
        };
    }

    update() {
        // Virtual function
    }

}