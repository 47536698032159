import PawnView from './pawnView.js';
import ItemView from './itemView.js';
import FxView from './fxView.js';
import { PawnStates, ATTACK_RADIUS } from './pawn.js';
import { SpriteTypes } from './spriteView.js';
import { TileDisplayMappings } from './tileDefinitions.js';

import { TILE_SIZE } from "./gameModel.js";

const TILE_UPDATE_INTERVAL = 3;

export default class GameView {

    constructor(userId, canvas, context, spritesheet, viewportWidth, viewportHeight, scaleMultiplier) {

        this.userId = userId;

        this.mainCanvas = canvas;
        this.mainContext = context;

        this.bufferWidth = viewportWidth + 1;
        this.bufferHeight = viewportHeight + 1;

        this.scaleMultiplier = scaleMultiplier;

        this.offsetX = 0;
        this.offsetY = 0;

        this.tileOriginX = 0;
        this.tileOriginY = 0;

        this.bufferCanvas = document.createElement('canvas');
        this.bufferCanvas.width = TILE_SIZE * this.bufferWidth * this.scaleMultiplier;
        this.bufferCanvas.height = TILE_SIZE * this.bufferHeight * this.scaleMultiplier;
        this.bufferContext = this.bufferCanvas.getContext('2d');

        this.bufferContext.imageSmoothingEnabled = false;
        this.mainContext.imageSmoothingEnabled = false;

        this.spritesheet = spritesheet;

        this.tiles = [];
        this.tileIds = [];
        this.tilesDict = null;
        this.tileUpdateCtr = 0;

        this._spriteViews = [];

        this.buildTilesDict();

    }

    buildTilesDict() {

        this.tilesDict = {};
        const tileKeys = Object.keys(TileDisplayMappings);

        for (let i = 0; i < tileKeys.length; i++) {

            const tileMapping = {
                key: tileKeys[i],
                id: TileDisplayMappings[tileKeys[i]].id,
                frames: TileDisplayMappings[tileKeys[i]].frames,
                currentFrame: 0,
            };

            this.tilesDict[tileMapping.id] = tileMapping;
            this.tileIds.push(tileMapping.id);
        }

    }

    update(tiles, gameObjects, gameObjectsToDraw, focalPoint) {

        if (!tiles || tiles.length === 0) {
            return;
        }

        this.tiles = tiles;

        let doResort = false;

        // Update game object visual states...
        for (let i = 0; i < gameObjects.length; i++) {

            let spriteView = null;

            for (let j = 0; j < this._spriteViews.length; j++) {
                if (gameObjects[i].id === this._spriteViews[j].id) {
                    spriteView = this._spriteViews[j];
                    break;
                }
            }

            if (!spriteView) {
                if (gameObjects[i].type === 'pawn') {
                    spriteView = new PawnView(gameObjects[i].id, gameObjects[i].index, gameObjects[i].size.x * this.scaleMultiplier, gameObjects[i].size.y * this.scaleMultiplier, this.scaleMultiplier);
                }
                if (gameObjects[i].type === 'item') {
                    spriteView = new ItemView(gameObjects[i].id, gameObjects[i].itemType, this.scaleMultiplier);
                }
                if (gameObjects[i].type === 'fx') {
                    spriteView = new FxView(gameObjects[i].id, gameObjects[i].fxType, this.scaleMultiplier);
                }
                this._spriteViews.push(spriteView);
                doResort = true;
            }

            spriteView.position = gameObjects[i].position;

            if (gameObjects[i].type === 'pawn' || gameObjects[i].type === 'item') {
                spriteView.active = gameObjects[i].active;
                spriteView.state = gameObjects[i].state;
                spriteView.currentIslandIndex = gameObjects[i].currentIslandIndex;
                spriteView.orientation = gameObjects[i].orientation;
            }

            spriteView.update();
        }

        // Find and remove any orphans...
        let _updatedSpriteViews = [];
        for (let i = 0; i < this._spriteViews.length; i++) {
            let goExists = false;
            for (let j = 0; j < gameObjects.length; j++) {
                if (this._spriteViews[i].id === gameObjects[j].id) {
                    goExists = true;
                    break;
                }
            }
            if (goExists) {
                _updatedSpriteViews.push(this._spriteViews[i]);
            }
        }
        this._spriteViews = _updatedSpriteViews;

        // Resort sprites for drawing order...
        if (doResort) {
            this._spriteViews.sort((a, b) => {
                if (a.type < b.type) {
                    return -1;
                }
                if (a.type > b.type) {
                    return 1;
                }
                return 0;
            });
        }

        // Clear previous frame...
        this.mainContext.clearRect(0, 0, this.mainCanvas.width, this.mainCanvas.height);
        this.bufferContext.clearRect(0, 0, this.bufferCanvas.width, this.bufferCanvas.height);

        // Calculate draw origins and offsets...
        this.offsetX = (focalPoint.x * this.scaleMultiplier) % (TILE_SIZE * this.scaleMultiplier);
        this.offsetY = (focalPoint.y * this.scaleMultiplier) % (TILE_SIZE * this.scaleMultiplier);

        if (this.bufferWidth % 2 === 0) {
            this.tileOriginX = Math.floor(((focalPoint.x * this.scaleMultiplier) - ((this.bufferWidth * TILE_SIZE * this.scaleMultiplier) / 2)) / (TILE_SIZE * this.scaleMultiplier));
        } else {
            this.tileOriginX = Math.round(((focalPoint.x * this.scaleMultiplier) - ((this.bufferWidth * TILE_SIZE * this.scaleMultiplier) / 2)) / (TILE_SIZE * this.scaleMultiplier));
        }

        if (this.bufferHeight % 2 === 0) {
            this.tileOriginY = Math.floor(((focalPoint.y * this.scaleMultiplier) - ((this.bufferHeight * TILE_SIZE * this.scaleMultiplier) / 2)) / (TILE_SIZE * this.scaleMultiplier));
        } else {
            this.tileOriginY = Math.round(((focalPoint.y * this.scaleMultiplier) - ((this.bufferHeight * TILE_SIZE * this.scaleMultiplier) / 2)) / (TILE_SIZE * this.scaleMultiplier));
        }

        if (this.tileOriginX < 0) {
            this.tileOriginX = 0;
            this.offsetX = 0;
        }

        if (this.tileOriginY < 0) {
            this.tileOriginY = 0;
            this.offsetY = 0;
        }

        if (this.tileOriginX + this.bufferWidth >= this.tiles[0].length) {
            this.tileOriginX = this.tiles[0].length - this.bufferWidth;
            this.offsetX = 0;
        }

        if (this.tileOriginY + this.bufferHeight >= this.tiles.length) {
            this.tileOriginY = this.tiles.length - this.bufferHeight;
            this.offsetY = 0;
        }

        // Draw updated content to buffer...
        this.drawBoard();

        for (let i = 0; i < this._spriteViews.length; i++) {
            if (gameObjectsToDraw.indexOf(this._spriteViews[i].id) > -1 && this._spriteViews[i].type === SpriteTypes.PAWN && this._spriteViews[i].state === PawnStates.ATTACK && this._spriteViews[i].showAttackRing) {
                this.drawAttackRing(this._spriteViews[i]);
            }
        }

        for (let i = 0; i < this._spriteViews.length; i++) {
            if (gameObjectsToDraw.indexOf(this._spriteViews[i].id) > -1) {
                this.drawSprite(this._spriteViews[i]);
            }
        }

        // Copy buffer to main...
        this.drawBufferToMain();

        // Update tile animations...
        if (this.tileUpdateCtr === TILE_UPDATE_INTERVAL) {
            for (let i = 0; i < this.tileIds.length; i++) {
                const id = this.tileIds[i];
                this.tilesDict[id].currentFrame++;
                if (this.tilesDict[id].currentFrame === this.tilesDict[id].frames.length) {
                    this.tilesDict[id].currentFrame = 0;
                }
            }
            this.tileUpdateCtr = 0;
        }
        this.tileUpdateCtr++;
    }

    drawBoard() {
        for (let row = 0; row < this.bufferHeight; row++) {
            for (let col = 0; col < this.bufferWidth; col++) {
                const tileMapping = this.tilesDict[this.tiles[this.tileOriginY + row][this.tileOriginX + col]];
                this.drawTileAtPosition(tileMapping, col, row);
            }
        }
    }

    drawTileAtPosition(tileData, positionX, positionY) {

        const originX = tileData.frames[tileData.currentFrame].positionX * TILE_SIZE * this.scaleMultiplier;
        const originY = tileData.frames[tileData.currentFrame].positionY * TILE_SIZE * this.scaleMultiplier;

        const targetX = positionX * TILE_SIZE * this.scaleMultiplier;
        const targetY = positionY * TILE_SIZE * this.scaleMultiplier;

        this.bufferContext.drawImage(
            this.spritesheet,
            originX, originY, TILE_SIZE * this.scaleMultiplier, TILE_SIZE * this.scaleMultiplier,
            targetX, targetY, TILE_SIZE * this.scaleMultiplier, TILE_SIZE * this.scaleMultiplier,
        );
    }

    drawAttackRing(spriteView) {

        const spriteX = spriteView.x * this.scaleMultiplier - this.tileOriginX * TILE_SIZE * this.scaleMultiplier;
        const spriteY = spriteView.y * this.scaleMultiplier - this.tileOriginY * TILE_SIZE * this.scaleMultiplier;

        this.bufferContext.drawImage(
            this.spritesheet, // src
            0, 128 * this.scaleMultiplier, // src rect origin x/y
            ATTACK_RADIUS * 2 * this.scaleMultiplier, ATTACK_RADIUS * 2 * this.scaleMultiplier, // src rect w/h
            spriteX + (spriteView.size.x / 2) - ATTACK_RADIUS * this.scaleMultiplier, spriteY + (spriteView.size.y / 2) - ATTACK_RADIUS * this.scaleMultiplier, // dest rect origin x/y
            ATTACK_RADIUS * 2 * this.scaleMultiplier, ATTACK_RADIUS * 2 * this.scaleMultiplier// dest rect w/h
        );

    }

    drawSprite(spriteView) {

        if (spriteView.active) {

            if (spriteView.x * this.scaleMultiplier >= this.tileOriginX * TILE_SIZE * this.scaleMultiplier &&
                spriteView.x * this.scaleMultiplier < (this.tileOriginX + this.bufferWidth) * TILE_SIZE * this.scaleMultiplier &&
                spriteView.y * this.scaleMultiplier >= this.tileOriginY * TILE_SIZE &&
                spriteView.y * this.scaleMultiplier < (this.tileOriginY + this.bufferHeight) * TILE_SIZE * this.scaleMultiplier
            ) {

                const spriteX = spriteView.x * this.scaleMultiplier - this.tileOriginX * TILE_SIZE * this.scaleMultiplier;
                const spriteY = spriteView.y * this.scaleMultiplier - this.tileOriginY * TILE_SIZE * this.scaleMultiplier;

                spriteView.context.save();

                spriteView.context.imageSmoothingEnabled = false;
                spriteView.context.clearRect(0, 0, spriteView.size.x * this.scaleMultiplier, spriteView.size.y * this.scaleMultiplier);

                if (spriteView.type === SpriteTypes.PAWN) {
                    if (spriteView.orientation === 'right') {
                        spriteView.context.translate(spriteView.size.x * this.scaleMultiplier, 0);
                        spriteView.context.scale(-1, 1);
                    }
                }

                spriteView.context.drawImage(
                    this.spritesheet, // src
                    spriteView.currentFrame.x * this.scaleMultiplier, spriteView.currentFrame.y * this.scaleMultiplier, // src rect origin x/y
                    spriteView.currentFrame.w * this.scaleMultiplier, spriteView.currentFrame.h * this.scaleMultiplier, // src rect w/h
                    0, 0, // dest rect origin x/y
                    spriteView.size.x * this.scaleMultiplier, spriteView.size.y * this.scaleMultiplier // dest rect w/h
                );

                spriteView.context.restore();

                this.bufferContext.drawImage(spriteView.canvas, spriteX, spriteY);
            }
        }

    }

    drawBufferToMain() {
        this.mainContext.drawImage(this.bufferCanvas, -this.offsetX, -this.offsetY);
    }
}