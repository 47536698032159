import PhysicsObject from './physicsObject.js';
import Vector2 from './vector2.js';

export const ITEM_SIZE = 24;

export const ItemTypes = {
    'GOGGLES': 0,
    'BOOTS': 1,
    'COMPASS': 2,
};

export const ItemStates = {
    'IDLE': 'idle',
    'DROPPED': 'dropped',
};

export default class Item extends PhysicsObject {

    constructor(id, itemType, x, y) {

        super(id, 'item', x, y, ITEM_SIZE, ITEM_SIZE + 4);

        this._itemType = itemType;
        this._state = ItemStates.IDLE;
        this._droppedTimer = 0;

    }

    get itemType() {
        return this._itemType;
    }

    get state() {
        return this._state;
    }

    set state(val) {

        if (this._state !== val) {
            this._state = val;
            if (this._state === ItemStates.DROPPED) {
                this._droppedTimer = 100;
            }
        }
    }

    update() {
        if (this._state === ItemStates.DROPPED) {
            this._droppedTimer--;
            if (this._droppedTimer === 0) {
                this._state = ItemStates.IDLE;
            }
        }
    }

    static cast(i) {

        const item = new Item(i._id, i._itemType, i._position._value[0], i._position._value[1]);
        item._state = i._state;
        item._droppedTimer = i._droppedTimer;
        item._active = i._active;
        item._velocity = new Vector2(i._velocity._value[0], i._velocity._value[1]);
        item._position = new Vector2(i._position._value[0], i._position._value[1]);
        item._friction = i._friction;
        item._frozenX = i._frozenX;
        item._frozenY = i._frozenY;
        item._currentIslandIndex = i._currentIslandIndex;
        return item;

    }

    static generateMinifiedDelta(currItem, prevItem) {

        const delta = {};

        // Only if changed values...
        if (!prevItem || currItem.active !== prevItem.active) {
            delta.active = currItem.active;
        }
        if (!prevItem || currItem.state !== prevItem.state) {
            delta.state = currItem.state;
        }
        if (!prevItem || currItem.itemType !== prevItem.itemType) {
            delta.itemType = currItem.itemType;
        }
        if (!prevItem || currItem.position.x !== prevItem.position.x) {
            delta.posX = currItem.position.x;
        }
        if (!prevItem || currItem.position.y !== prevItem.position.y) {
            delta.posY = currItem.position.y;
        }
        if (!prevItem || currItem.velocity.x !== prevItem.velocity.x) {
            delta.velX = currItem.velocity.x;
        }
        if (!prevItem || currItem.velocity.y !== prevItem.velocity.y) {
            delta.velY = currItem.velocity.y;
        }
        if (!prevItem || currItem.friction !== prevItem.friction) {
            delta.friction = currItem.friction;
        }
        if (!prevItem || currItem.frozenX !== prevItem.frozenX) {
            delta.frozenX = currItem.frozenX;
        }
        if (!prevItem || currItem.frozenY !== prevItem.frozenY) {
            delta.frozenY = currItem.frozenY;
        }
        if (!prevItem || currItem._currentIslandIndex !== prevItem._currentIslandIndex) {
            delta.currentIslandIndex = currItem._currentIslandIndex;
        }
        if (!prevItem || currItem._droppedTimer !== prevItem._droppedTimer) {
            delta.droppedTimer = currItem._droppedTimer;
        }

        // Guranteed values...
        if (Object.keys(delta).length > 0) {
            delta.id = currItem.id;
            delta.type = 'item';
        }

        return delta;
    }

    static castFromMinified(minified) {

        const item = new Item(minified.id, minified.itemType, minified.posX, minified.posY);
        return Item.applyMinifiedValues(item, minified);

    }

    static applyMinifiedValues(item, minified) {

        const keys = Object.keys(minified);
        for (let i = 0; i < keys.length; i++) {

            const key = keys[i];
            const value = minified[key];

            if (key === 'active') {
                item.active = value;
            }
            if (key === 'state') {
                item.state = value;
            }
            if (key === 'itemType') {
                item._itemType = value;
            }
            if (key === 'posX') {
                item.position.x = value;
            }
            if (key === 'posY') {
                item.position.y = value;
            }
            if (key === 'velX') {
                item.velocity.x = value;
            }
            if (key === 'velY') {
                item.velocity.y = value;
            }
            if (key === 'friction') {
                item._friction = value;
            }
            if (key === 'frozenX') {
                item._frozenX = value;
            }
            if (key === 'frozenY') {
                item._frozenY = value;
            }
            if (key === 'currentIslandIndex') {
                item._currentIslandIndex = value;
            }
            if (key === 'droppedTimer') {
                item._droppedTimer = value;
            }

        }

        return item;
    }
}