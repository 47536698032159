
const types = {

    "UP": "up",
    "DOWN": "down",
    "LEFT": "left",
    "RIGHT": "right",
    "JUMP": "jump",
    "ATTACK": "attack",
    "DEBUG": "debug"

}

export default types;
