
export default class StateUpdate {

    constructor() {

        this._values = {};

    }

    get values() {
        return this._values;
    }

    setValue(key, val) {
        this._values[key] = val;
    }

    static cast(obj) {
        const stateUpdate = new StateUpdate();
        stateUpdate._values = obj._values;
        return stateUpdate;
    }

}