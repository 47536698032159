import PhysicsObject from './physicsObject.js';
import Vector2 from './vector2.js';

export const PawnStates = {
    'IDLE': 'idle',
    'WALK': 'walk',
    'FALL': 'fall',
    'JUMP': 'jump',
    'DOUBLE_JUMP': 'double-jump',
    'ATTACK': 'attack',
    'DAZED': 'dazed',
};

export const PAWN_SIZE = 24;
export const JUMP_COOLDOWN_TIME = 20;
export const DAZE_COOLDOWN_TIME = 200;
export const ATTACK_TIME = 20;
export const ATTACK_RADIUS = 100;
export const ATTACK_COOLDOWN_TIME = 1000;

export default class Pawn extends PhysicsObject {

    constructor(id, index, x, y) {

        super(id, 'pawn', x, y, PAWN_SIZE, PAWN_SIZE);

        this._index = index;
        this._state = PawnStates.IDLE;
        this._speed = 6;
        this._jumpHeight = 17;
        this._walkCooldown = 0;
        this._jumpCooldown = 0;
        this._attackCooldown = 0;
        this._attackTime = 0;
        this._dazeCooldown = 0;
        this._items = [];
        this._orientation = 'left';

    }

    get index() {
        return this._index;
    }

    get state() {
        return this._state;
    }

    set state(val) {
        this._state = val;
    }

    get speed() {
        return this._speed;
    }

    get jumpHeight() {
        return this._jumpHeight;
    }

    get items() {
        return this._items;
    }

    get orientation() {
        return this._orientation;
    }

    set orientation(val) {
        this._orientation = val;
    }

    get walkCooldown() {
        return this._walkCooldown;
    }

    set walkCooldown(val) {
        this._walkCooldown = val;
    }

    get jumpCooldown() {
        return this._jumpCooldown;
    }

    set jumpCooldown(val) {
        this._jumpCooldown = val;
    }

    get dazeCooldown() {
        return this._dazeCooldown;
    }

    set dazeCooldown(val) {
        this._dazeCooldown = val;
    }

    get attackCooldown() {
        return this._attackCooldown;
    }

    set attackCooldown(val) {
        this._attackCooldown = val;
    }

    get attackTime() {
        return this._attackTime;
    }

    set attackTime(val) {
        this._attackTime = val;
    }

    // Methods

    pickupItem(item) {
        this._items.push(item);
    }

    dropAllItems() {
        this._items = [];
    }

    static cast(p) {

        const pawn = new Pawn(p._id, p._index, p._position._value[0], p._position._value[1]);
        pawn._active = p._active;
        pawn._state = p._state;
        pawn._velocity = new Vector2(p._velocity._value[0], p._velocity._value[1]);
        pawn._friction = p._friction;
        pawn._jumpHeight = p._jumpHeight;
        pawn._walkCooldown = p._walkCooldown;
        pawn._jumpCooldown = p._jumpCooldown;
        pawn._dazeCooldown = p._dazeCooldown;
        pawn._attackTime = p._attackTime;
        pawn._attackCooldown = p._attackCooldown;
        pawn._items = p._items;
        pawn._frozenX = p._frozenX;
        pawn._frozenY = p._frozenY;
        pawn._currentIslandIndex = p._currentIslandIndex;
        pawn._orientation = p._orientation;
        return pawn;

    }

    static generateMinifiedDelta(currPawn, prevPawn) {

        const delta = {}

        // Only if changed values...
        if (!prevPawn || currPawn.index !== prevPawn.index) {
            delta.index = currPawn.index;
        }
        if (!prevPawn || currPawn.active !== prevPawn.active) {
            delta.active = currPawn.active;
        }
        if (!prevPawn || currPawn.state !== prevPawn.state) {
            delta.state = currPawn.state;
        }
        if (!prevPawn || currPawn.position.x !== prevPawn.position.x) {
            delta.posX = currPawn.position.x;
        }
        if (!prevPawn || currPawn.position.y !== prevPawn.position.y) {
            delta.posY = currPawn.position.y;
        }
        if (!prevPawn || currPawn.velocity.x !== prevPawn.velocity.x) {
            delta.velX = currPawn.velocity.x;
        }
        if (!prevPawn || currPawn.velocity.y !== prevPawn.velocity.y) {
            delta.velY = currPawn.velocity.y;
        }
        if (!prevPawn || currPawn.friction !== prevPawn.friction) {
            delta.friction = currPawn.friction;
        }
        if (!prevPawn || currPawn.jumpHeight !== prevPawn.jumpHeight) {
            delta.jumpHeight = currPawn.jumpHeight;
        }
        if (!prevPawn || currPawn.walkCooldown !== prevPawn.walkCooldown) {
            delta.walkCooldown = currPawn.walkCooldown;
        }
        if (!prevPawn || currPawn.jumpCooldown !== prevPawn.jumpCooldown) {
            delta.jumpCooldown = currPawn.jumpCooldown;
        }
        if (!prevPawn || currPawn.dazeCooldown !== prevPawn.dazeCooldown) {
            delta.dazeCooldown = currPawn.dazeCooldown;
        }
        if (!prevPawn || currPawn.attackTime !== prevPawn.attackTime) {
            delta.attackTime = currPawn.attackTime;
        }
        if (!prevPawn || currPawn.attackCooldown !== prevPawn.attackCooldown) {
            delta.attackCooldown = currPawn.attackCooldown;
        }
        if (!prevPawn || currPawn.frozenX !== prevPawn.frozenX) {
            delta.frozenX = currPawn.frozenX;
        }
        if (!prevPawn || currPawn.frozenY !== prevPawn.frozenY) {
            delta.frozenY = currPawn.frozenY;
        }
        if (!prevPawn || currPawn.currentIslandIndex !== prevPawn.currentIslandIndex) {
            delta.currentIslandIndex = currPawn.currentIslandIndex;
        }
        if (!prevPawn || currPawn.orientation !== prevPawn.orientation) {
            delta.orientation = currPawn.orientation;
        }
        if (!prevPawn || currPawn.items.length !== prevPawn.items.length) {
            delta.items = currPawn.items;
        }

        // Guranteed values...
        if (Object.keys(delta).length > 0) {
            delta.id = currPawn.id;
            delta.type = 'pawn';
        }

        return delta;
    }

    static castFromMinified(minified) {

        const pawn = new Pawn(minified.id, minified.index, minified.posX || 0, minified.posY || 0);
        return Pawn.applyMinifiedValues(pawn, minified);

    }

    static applyMinifiedValues(pawn, minified) {

        const keys = Object.keys(minified);
        for (let i = 0; i < keys.length; i++) {

            const key = keys[i];
            const value = minified[key];

            if (key === 'active') {
                pawn.active = value;
            }
            if (key === 'state') {
                pawn.state = value;
            }
            if (key === 'posX') {
                pawn.position.x = value;
            }
            if (key === 'posY') {
                pawn.position.y = value;
            }
            if (key === 'velX') {
                pawn.velocity.x = value;
            }
            if (key === 'velY') {
                pawn.velocity.y = value;
            }
            if (key === 'friction') {
                pawn._friction = value;
            }
            if (key === 'jumpHeight') {
                pawn._jumpHeight = value;
            }
            if (key === 'jumpCooldown') {
                pawn.jumpCooldown = value;
            }
            if (key === 'walkCooldown') {
                pawn.walkCooldown = value;
            }
            if (key === 'dazeCooldown') {
                pawn.dazeCooldown = value;
            }
            if (key === 'attackTime') {
                pawn.attackTime = value;
            }
            if (key === 'attackCooldown') {
                pawn.attackCooldown = value;
            }
            if (key === 'frozenX') {
                pawn._frozenX = value;
            }
            if (key === 'frozenY') {
                pawn._frozenY = value;
            }
            if (key === 'currentIslandIndex') {
                pawn._currentIslandIndex = value;
            }
            if (key === 'orientation') {
                pawn.orientation = value;
            }
            if (key === 'items') {
                pawn._items = value;
            }
        }

        return pawn;
    }

}