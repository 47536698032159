
export const TileTypes = {
    'NULL': -1,
    'WALKABLE': 0,
    'UNWALKABLE': 1,
    'SPAWNPOINT': 2,
    'EXIT': 3,
    'DOOR': 4,
    'POWERUP_A': 5,
    'POWERUP_B': 6,
    'POWERUP_C': 7,
};

export const TileDisplayTypes = {
    'NULL': -1,
    'HIDDEN': 0,
    'REVEALED': 1,
    'REVEALED_INACTIVE': 2,
    'EXIT': 3,
    'DOOR': 4,
    'DOOR_INACTIVE': 5,
};

export const TileDisplayMappings = {

    'NULL': {
        'id': -1,
        'frames': [
            {
                'positionX': 0,
                'positionY': 0
            }
        ]
    },
    'HIDDEN': {
        'id': 0,
        'frames': [
            {
                'positionX': 0,
                'positionY': 0
            }
        ]
    },
    'REVEALED': {
        'id': 1,
        'frames': [
            {
                'positionX': 1,
                'positionY': 0
            }
        ]
    },
    'REVEALED_INACTIVE': {
        'id': 2,
        'frames': [
            {
                'positionX': 2,
                'positionY': 0
            }
        ]
    },
    'EXIT':
    {
        'id': 3,
        'frames': [
            {
                'positionX': 0,
                'positionY': 1
            },
            {
                'positionX': 1,
                'positionY': 1
            },
            {
                'positionX': 2,
                'positionY': 1
            },
            {
                'positionX': 3,
                'positionY': 1
            },
        ]
    },
    'DOOR':
    {
        'id': 4,
        'frames': [
            {
                'positionX': 4,
                'positionY': 0
            }
        ]
    },
    'DOOR_INACTIVE':
    {
        'id': 5,
        'frames': [
            {
                'positionX': 3,
                'positionY': 0
            }
        ]
    },

}