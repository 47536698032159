<template>
    <div class="waiting-container" v-if="showWaiting">
        <h2>
            <span class="__arrows">{{ loadingStringBackward }}</span> WAITING FOR CONNECTION
            <span class="__arrows">{{ loadingStringForward }}</span>
        </h2>
    </div>
</template>

<script>
export default {
    name: 'Waiting',
    components: {},
    data() {
        return {
            animInterval: null,
            animTime: 0,
            showWaiting: false,
        };
    },
    computed: {
        loadingStringForward() {
            if (this.animTime === 2) {
                return '  >';
            }
            if (this.animTime === 1) {
                return ' > ';
            }
            if (this.animTime === 0) {
                return '>  ';
            }
            return '   ';
        },
        loadingStringBackward() {
            if (this.animTime === 0) {
                return '  <';
            }
            if (this.animTime === 1) {
                return ' < ';
            }
            if (this.animTime === 2) {
                return '<  ';
            }
            return '   ';
        },
    },
    methods: {
        delayShowWaiting() {
            setTimeout(() => {
                this.showWaiting = true;
                this.animInterval = setInterval(this.incrementAnimTime, 250);
            }, 3000);
        },
        incrementAnimTime() {
            this.animTime++;
            if (this.animTime === 3) {
                this.animTime = 0;
            }
        },
    },
    created() {
        this.delayShowWaiting();
    },
    beforeUnmount() {
        if (this.animInterval) {
            clearInterval(this.animInterval);
        }
    },
};
</script>

<style lang="scss" scoped>
.waiting-container {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {
        white-space: pre-wrap;
    }
    .__arrows {
        color: $color-lines;
    }
}
</style>
