import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import VueSocketIO from "vue-3-socket.io";
import SocketIO from "socket.io-client";

import './styles/main.scss';

// If the app is running locally, point to a localhost address, otherwise point to an IP
let serverIp =
    process.env.VUE_APP_SERVER_ENDPOINT_REMOTE + ":" + process.env.VUE_APP_PORT;

if (window.location.href.indexOf("localhost") >= 0) {
    serverIp =
        process.env.VUE_APP_SERVER_ENDPOINT_LOCAL + ":" + process.env.VUE_APP_PORT;
}

const socket = new VueSocketIO({
    debug: false,
    connection: SocketIO(serverIp, {
        withCredentials: true,
        extraHeaders: {
            "my-custom-header": "abcd"
        }
    }),
});

createApp(App).use(router).use(socket).mount("#app");
