<template>
    <transition appear name="dialog-transition">
        <div class="dialog-container">
            <div class="basic-dialog">
                <slot name="content"
                    ><p>{{ body }}</p></slot
                >
                <div class="__footer">
                    <button
                        v-if="optionALabel !== ''"
                        class="--p10 --w100"
                        v-bind:class="{ '--secondary-button': optionBLabel !== '' }"
                        v-bind:disabled="disableButton"
                        v-on:click="optionAClicked"
                    >
                        {{ optionALabel }}
                    </button>
                    <button v-if="optionBLabel !== ''" class="--p10 --w100" v-bind:disabled="disableButton" v-on:click="optionBClicked">
                        {{ optionBLabel }}
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'BasicDialog',
    props: {
        title: {
            type: String,
            default: 'Default title',
        },
        optionALabel: {
            type: String,
            default: '',
        },
        optionBLabel: {
            type: String,
            default: '',
        },
        timeout: {
            type: Number,
            default: 0,
        },
        body: {
            type: String,
            default: 'Default content',
        },
    },
    data() {
        return {
            disableButton: false,
        };
    },
    computed: {},
    methods: {
        optionAClicked() {
            this.$emit('option-a-clicked');
        },
        optionBClicked() {
            this.$emit('option-b-clicked');
        },
    },
    mounted() {
        if (this.timeout > 0) {
            this.disableButton = true;
            setTimeout(() => {
                this.disableButton = false;
            }, this.timeout * 1000);
        }
    },
};
</script>
<style lang="scss" scoped>
.dialog-transition-enter-from,
.dialog-transition-leave-to {
    opacity: 0;
}

.dialog-transition-enter-active {
    transition: all 0s ease-in-out;
}

.dialog-transition-leave-active {
    transition: all 0s ease-in-out;
}

.dialog-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.basic-dialog {
    position: relative;
    border: solid 2px white;
    background-color: black;
    padding: 20px;
    .__footer {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        gap: 10px;
    }
}

.--w100 {
    min-width: 140px;
}
</style>
