
export default class Vector2 {

    constructor(x, y) {
        this._value = [x, y];
    }

    get x() {
        return this._value[0];
    }

    set x(val) {
        this._value[0] = val;
    }

    get y() {
        return this._value[1];
    }

    set y(val) {
        this._value[1] = val;
    }

    static add(vecA, vecB) {
        return new Vector2(
            vecA.x + vecB.x,
            vecA.y + vecB.y
        );
    }

    static average(vecA, vecB) {
        return new Vector2((vecA.x + vecB.x) / 2, (vecA.y + vecB.y) / 2);
    }

    static lerp(from, to, currentFrames, totalFrames) {
        const v2 = new Vector2(0, 0);
        v2.x = Math.floor(from.x + (to.x - from.x) * (currentFrames / totalFrames));
        v2.y = Math.floor(from.y + (to.y - from.y) * (currentFrames / totalFrames));
        return v2;
    }

    static cast(v2) {
        return new Vector2(v2._value[0], v2._value[1]);
    }

    static distance(v2a, v2b) {
        return Math.sqrt(Math.pow(v2a.x - v2b.x, 2) + Math.pow(v2a.y - v2b.y, 2));
    }
}