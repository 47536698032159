import SpriteView from './spriteView.js';
import { SpriteTypes } from './spriteView.js';
import { FX_SIZE, FxTypes } from './fx.js';

const FxAnimations = {
    ITEM_PICKUP: {
        loop: false,
        interval: 3,
        frames: [
            {
                x: 320,
                y: 312,
                w: FX_SIZE,
                h: FX_SIZE,
            },
            {
                x: 320 + FX_SIZE,
                y: 312,
                w: FX_SIZE,
                h: FX_SIZE,
            },
            {
                x: 320 + FX_SIZE * 2,
                y: 312,
                w: FX_SIZE,
                h: FX_SIZE,
            },
            {
                x: 320 + FX_SIZE * 3,
                y: 312,
                w: FX_SIZE,
                h: FX_SIZE,
            },
            {
                x: 320 + FX_SIZE * 4,
                y: 312,
                w: FX_SIZE,
                h: FX_SIZE,
            },
            {
                x: 320 + FX_SIZE * 5,
                y: 312,
                w: FX_SIZE,
                h: FX_SIZE,
            },
        ]
    },
    PLAYER_ENTER: {
        interval: 3,
        loop: false,
        frames: [
            {
                x: 320,
                y: 336,
                w: 24,
                h: 24,
            },
            {
                x: 320 + FX_SIZE,
                y: 336,
                w: 24,
                h: 24,
            },
            {
                x: 320 + FX_SIZE * 2,
                y: 336,
                w: 24,
                h: 24,
            },
            {
                x: 320 + FX_SIZE * 3,
                y: 336,
                w: 24,
                h: 24,
            },
            {
                x: 320 + FX_SIZE * 4,
                y: 336,
                w: 24,
                h: 24,
            },
            {
                x: 320 + FX_SIZE * 5,
                y: 336,
                w: 24,
                h: 24,
            },
        ]
    },
}

export default class FxView extends SpriteView {

    constructor(id, type, scale) {

        super(id, 0, 0, FX_SIZE * scale, FX_SIZE * scale, SpriteTypes.EFFECT, scale);

        this._intervalCtr = 0;
        this._currentFrame = 0;

        this._currentAnimation = FxAnimations.PLAYER_ENTER;
        if (type === FxTypes.PLAYER_ENTER) {
            this._currentAnimation = FxAnimations.PLAYER_ENTER;
        }
        if (type === FxTypes.ITEM_PICKUP) {
            this._currentAnimation = FxAnimations.ITEM_PICKUP;
        }

    }

    get currentFrame() {
        return {
            x: this._currentAnimation.frames[this._currentFrame].x,
            y: this._currentAnimation.frames[this._currentFrame].y,// * (this.size.y * (1 / this._scale)),
            w: this._currentAnimation.frames[this._currentFrame].w,
            h: this._currentAnimation.frames[this._currentFrame].h,
        };
    }

    update() {
        if (this._intervalCtr === this._currentAnimation.interval) {
            this._intervalCtr = 0;
            if (this._currentAnimation.loop) {
                this._currentFrame++;
                if (this._currentFrame === this._currentAnimation.frames.length) {
                    this._currentFrame = 0;
                }
            } else if (this._currentFrame < this._currentAnimation.frames.length - 1) {
                this._currentFrame++;
            }
        }
        this._intervalCtr++;
    }

}