import Vector2 from './vector2.js';

export const FX_SIZE = 24;
export const FxTypes = {
    'PLAYER_ENTER': 0,
    'ITEM_PICKUP': 1,
};

export default class Fx {

    constructor(id, type, x, y) {

        this._type = 'fx';
        this._fxType = type;
        this._id = id;
        this._position = new Vector2(x, y);
        this._size = new Vector2(FX_SIZE, FX_SIZE);
        this._lifespan = 15;
        this._lifeCtr = 0;
        this._complete = false;

    }

    get id() {
        return this._id;
    }

    get type() {
        return this._type;
    }

    get fxType() {
        return this._fxType;
    }

    get size() {
        return this._size;
    }

    get position() {
        return this._position;
    }

    set position(val) {
        this._position = val;
    }

    get x() {
        return this._position.x;
    }

    set x(val) {
        this._position.x = val;
    }

    get y() {
        return this._position.y;
    }

    set y(val) {
        this._position.y = val;
    }

    get complete() {
        return this._complete;
    }

    set complete(val) {
        this._complete = val;
    }

    update() {
        if (this._lifeCtr < this._lifespan) {
            this._lifeCtr++;
            if (this._lifeCtr === this._lifespan) {
                this._complete = true;
            }
        }
    }

}