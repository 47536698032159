import SpriteView from './spriteView.js';
import { SpriteTypes } from './spriteView.js';
import { PawnStates } from './pawn.js';

const PawnAnimations = {
    IDLE: {
        loop: true,
        interval: 3,
        frames: [
            {
                x: 320,
                y: 0,
                w: 24,
                h: 24,
            },
            {
                x: 488,
                y: 0,
                w: 24,
                h: 24,
            },
            {
                x: 464,
                y: 24,
                w: 24,
                h: 24,
            },
            {
                x: 488,
                y: 24,
                w: 24,
                h: 24,
            },
            {
                x: 464,
                y: 24,
                w: 24,
                h: 24,
            },
            {
                x: 488,
                y: 0,
                w: 24,
                h: 24,
            },
        ]
    },
    WALK: {
        loop: true,
        interval: 3,
        frames: [
            {
                x: 416,
                y: 0,
                w: 24,
                h: 24,
            },
            {
                x: 440,
                y: 0,
                w: 24,
                h: 24,
            },
            {
                x: 464,
                y: 0,
                w: 24,
                h: 24,
            },
            {
                x: 440,
                y: 0,
                w: 24,
                h: 24,
            },
        ]
    },
    JUMP: {
        loop: false,
        interval: 3,
        frames: [
            {
                x: 320,
                y: 0,
                w: 24,
                h: 24,
            },
            {
                x: 320,
                y: 24,
                w: 24,
                h: 24,
            },
            {
                x: 344,
                y: 24,
                w: 24,
                h: 24,
            },
            {
                x: 368,
                y: 24,
                w: 24,
                h: 24,
            },
            {
                x: 368,
                y: 24,
                w: 24,
                h: 24,
            },
            {
                x: 392,
                y: 24,
                w: 24,
                h: 24,
            },
            {
                x: 416,
                y: 24,
                w: 24,
                h: 24,
            },
            {
                x: 440,
                y: 24,
                w: 24,
                h: 24,
            },
        ]
    },
    DAZED: {
        loop: true,
        interval: 6,
        frames: [
            {
                x: 320,
                y: 48,
                w: 24,
                h: 24,
            },
            {
                x: 488,
                y: 48,
                w: 24,
                h: 24,
            },
            {
                x: 368,
                y: 48,
                w: 24,
                h: 24,
            },

            {
                x: 488,
                y: 48,
                w: 24,
                h: 24,
            },
        ]
    },
    ATTACK: {
        loop: false,
        interval: 3,
        frames: [
            {
                x: 416,
                y: 48,
                w: 24,
                h: 24,
            },
        ]
    },
}

export default class PawnView extends SpriteView {

    constructor(id, index, w, h, scale) {

        super(id, 0, 0, w, h, SpriteTypes.PAWN, scale);

        this._pawnIndex = index;
        this._intervalCtr = 0;
        this._currentFrame = 0;
        this._currentAnimation = PawnAnimations.IDLE;
        this._state = PawnStates.IDLE;
        this._showAttackRing = false;
        this._attackRingCounter = 0;

    }

    get orientation() {
        return this._orientation;
    }

    set orientation(val) {

        if (this._orientation === val) {
            return;
        }

        this._orientation = val;
        this.resetAnimation();
    }

    get state() {
        return this._state;
    }

    set state(val) {

        if (this._state === val) {
            return;
        }

        this._state = val;
        this.resetAnimation();

        if (this._state === PawnStates.ATTACK) {
            this._showAttackRing = true;
            this._attackRingCounter = 0;
        }
    }

    get currentFrame() {
        return {
            x: this._currentAnimation.frames[this._currentFrame].x,
            y: this._currentAnimation.frames[this._currentFrame].y + this._pawnIndex * (this.size.y * (1 / this._scale) * 3),
            w: this._currentAnimation.frames[this._currentFrame].w,
            h: this._currentAnimation.frames[this._currentFrame].h,
        };
    }

    get showAttackRing() {
        return this._showAttackRing;
    }

    resetAnimation() {
        if (this._state === PawnStates.WALK) {
            this._currentAnimation = PawnAnimations.WALK;
        }
        else if (this._state === PawnStates.FALL || this._state === PawnStates.JUMP || this._state === PawnStates.DOUBLE_JUMP) {
            this._currentAnimation = PawnAnimations.JUMP;
        }
        else if (this._state === PawnStates.DAZED) {
            this._currentAnimation = PawnAnimations.DAZED;
        }
        else if (this._state === PawnStates.ATTACK) {
            this._currentAnimation = PawnAnimations.ATTACK;
        }
        else {
            this._currentAnimation = PawnAnimations.IDLE;
        }
        this._currentFrame = 0;
        this._intervalCtr = 0;
    }

    update() {
        if (this._intervalCtr === this._currentAnimation.interval) {
            this._intervalCtr = 0;
            if (this._currentAnimation.loop) {
                this._currentFrame++;
                if (this._currentFrame === this._currentAnimation.frames.length) {
                    this._currentFrame = 0;
                }
            } else if (this._currentFrame < this._currentAnimation.frames.length - 1) {
                this._currentFrame++;
            }
        }
        this._intervalCtr++;

        if (this.state === PawnStates.ATTACK) {
            if (this._attackRingCounter === 0) {
                this._showAttackRing = true;
            }
            if (this._attackRingCounter === 6) {
                this._showAttackRing = false;
            }
            this._attackRingCounter++;
            if (this._attackRingCounter === 12) {
                this._attackRingCounter = 0;
            }
        }
    }

}