import Pawn from "./pawn.js";
import Item from "./item.js";
import Vector2 from "./vector2.js";

export const TILE_SIZE = 64;

export default class GameModel {

    constructor() {
        this._phase = '';
        this._time = 0;
        this._winner = '';
        this._gameObjects = [];
        this._spawnPoints = [];
        this._tiles = [];
        this._islands = [];
        this._islandsDict = [];
        this._exitPosition = new Vector2(0, 0);
    }

    get phase() {
        return this._phase;
    }

    set phase(val) {
        this._phase = val;
    }

    get time() {
        return this._time;
    }

    set time(val) {
        this._time = val;
    }

    get winner() {
        return this._winner;
    }

    set winner(val) {
        this._winner = val;
    }

    get gameObjects() {
        return this._gameObjects;
    }

    set gameObjects(val) {
        this._gameObjects = val;
    }

    get spawnPoints() {
        return this._spawnPoints;
    }

    set spawnPoints(points) {
        this._spawnPoints = points;
    }

    get tiles() {
        return this._tiles;
    }

    set tiles(val) {
        this._tiles = val;
    }

    get islands() {
        return this._islands;
    }

    set islands(val) {
        this._islands = val;
    }

    get islandsDict() {
        return this._islandsDict;
    }

    set islandsDict(val) {
        this._islandsDict = val;
    }

    get exitPosition() {
        return this._exitPosition;
    }

    set exitPosition(val) {
        this._exitPosition = val;
    }

    static cast(model) {
        const gameModel = new GameModel();
        gameModel.phase = model._phase;
        gameModel.time = model._time;
        gameModel.winner = model._winner;
        gameModel.spawnPoints = model._spawnPoints;
        gameModel.tiles = model._tiles;
        gameModel.islands = model._islands.map(island => {
            return {
                id: island.id,
                type: island.type,
                tiles: island.tiles.map(tileV2 => {
                    return Vector2.cast(tileV2);
                }),
                doors: island.doors.map(doorV2 => {
                    return Vector2.cast(doorV2);
                }),
            };
        });
        gameModel.islandsDict = JSON.parse(JSON.stringify(model._islandsDict));
        gameModel.exitPosition = Vector2.cast(model._exitPosition);
        gameModel.gameObjects = model._gameObjects.map(go => {
            if (go._type === 'pawn') {
                return Pawn.cast(go);
            }
            if (go._type === 'item') {
                return Item.cast(go);
            }
            return null;
        });
        return gameModel;
    }

    static copy(model) {
        const copy = JSON.parse(JSON.stringify(model));
        return GameModel.cast(copy);
    }

}