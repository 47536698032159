
export default class Command {

    constructor(id, userId, type, time) {

        this._id = id;
        this._userId = userId;
        this._type = type;
        this._time = time;

    }

    get id() {
        return this._id;
    }

    get userId() {
        return this._userId;
    }

    get type() {
        return this._type;
    }

    get time() {
        return this._time;
    }

    static cast(command) {
        return new Command(command._id, command._userId, command._type, command._time);
    }
}