import Vector2 from './vector2.js';

export default class PhysicsObject {

    constructor(id, type, x, y, w, h) {

        this._id = id;
        this._type = type;
        this._active = true;
        this._position = new Vector2(x, y);
        this._size = new Vector2(w, h);
        this._velocity = new Vector2(0, 0);
        this._friction = 0.5;
        this._frozenX = false;
        this._frozenY = false;
        this._currentIslandIndex = -1;

    }

    get id() {
        return this._id;
    }

    get type() {
        return this._type;
    }

    get active() {
        return this._active;
    }

    set active(val) {
        this._active = val;
    }

    get size() {
        return this._size;
    }

    get friction() {
        return this._friction;
    }

    // Bounding box

    get top() {
        return this._position.y;
    }

    get left() {
        return this._position.x;
    }

    get bottom() {
        return this._position.y + this._size.y - 1;
    }

    get right() {
        return this._position.x + this._size.x - 1;
    }

    get frozenX() {
        return this._frozenX;
    }

    set frozenX(val) {
        this._frozenX = val;
    }

    get frozenY() {
        return this._frozenY;
    }

    set frozenY(val) {
        this._frozenY = val;
    }

    // Velocity

    get velocity() {
        return this._velocity;
    }

    set velocity(val) {
        this._velocity = val;
    }

    get velX() {
        return this._velocity.x;
    }

    get velY() {
        return this._velocity.y;
    }

    set velX(val) {
        this._velocity.x = val;
    }

    set velY(val) {
        this._velocity.y = val;
    }

    // Position

    get position() {
        return this._position;
    }

    set position(val) {
        this._position = val;
    }

    get x() {
        return this._position.x;
    }

    set x(val) {
        this._position.x = val;
    }

    get y() {
        return this._position.y;
    }

    set y(val) {
        this._position.y = val;
    }

    get currentIslandIndex() {
        return this._currentIslandIndex;
    }

    set currentIslandIndex(val) {
        this._currentIslandIndex = val;
    }

    // Methods

    update() {
        // Virtual function...
    }

    unfreezeAxes() {
        this._frozenX = false;
        this._frozenY = false;
    }

}